import { Avatar, AvatarProps } from '@chakra-ui/react'
import { useRef } from 'react'
import { avatarColors } from '../../theme/styles'

interface Props extends Omit<AvatarProps, 'title'> {}

const AvatarIcon: React.FC<Props> = ({ ...props }) => {
  const { current } = useRef(
    (() => {
      const rand = avatarColors[Math.floor(Math.random() * avatarColors.length)]
      return rand
    })(),
  )

  return (
    <Avatar
      style={{ boxShadow: '0 0 2.5px 0.1px hsl(0, 0%, 0%)' }}
      bg={current}
      color={'white'}
      {...props}
    />
  )
}

export default AvatarIcon
