import { Box, Center, Heading, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import Layout from '../../components/Layout'

const ProfileNotFoundPage = () => {
  const { t } = useTranslation()

  return (
    <Layout backButton>
      <Center>
        <Box textAlign="center" mt={10}>
          <Heading> 404 </Heading>
          <Text color="black"> {t('profileNotFound')} </Text>
        </Box>
      </Center>
    </Layout>
  )
}

export default ProfileNotFoundPage
