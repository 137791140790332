import { Flex, Text } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import FAIcon from './FAIcon'

type Props = {
  text: string
  color: string
  iconColor: string
  to?: string
  onClick?: () => void
}

const SettingItem = ({
  text,
  color,
  iconColor,
  to = '',
  onClick = () => {},
}: Props) => {
  if (to !== '') {
    return (
      <Link
        to={to}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          alignSelf: 'flex-start',
          justifyContent: 'center',
          paddingBottom: 34,
        }}
      >
        <Text
          color={color}
          fontSize="large"
          fontWeight="semibold"
          paddingRight="3"
        >
          {text}
        </Text>
        <FAIcon icon={'chevron-right'} color={iconColor} />
      </Link>
    )
  }
  return (
    <Flex
      onClick={onClick}
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        alignSelf: 'flex-start',
        justifyContent: 'center',
        paddingBottom: 5,
        cursor: 'pointer',
      }}
    >
      <Text
        color={color}
        fontSize="large"
        fontWeight="semibold"
        paddingRight="3"
      >
        {text}
      </Text>
      <FAIcon icon={'chevron-right'} color={iconColor} />
    </Flex>
  )
}

export default SettingItem
