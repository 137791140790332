import { HamburgerIcon } from '@chakra-ui/icons'
import { Image } from '@chakra-ui/image'
import { Flex } from '@chakra-ui/layout'
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  IconButton,
  useDisclosure,
  Text,
  Box,
} from '@chakra-ui/react'
import { t } from 'i18next'
import React from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../hooks'
import useProfilePicture from '../../../hooks/useProfilePicture'
import { defaultPadding } from '../../../theme'
import { primaryColor } from '../../../theme/styles'
import AvatarIcon from '../../basic/AvatarIcon'
import NavItem from '../../NavItem'
import NotificationBanner from '../../NotificationBanner'
import SettingItem from '../../SettingItem'

const TopBar: React.FC<{
  bgBehind?: boolean
  hideLogo?: boolean
  LeftComponent?: any
  isLargerThan1000?: boolean
  titleText?: string
  showBanner: boolean
  bannerTitle: string
  bannerText: string
  bannerAction?: () => void
}> = ({
  bgBehind,
  hideLogo,
  LeftComponent,
  isLargerThan1000,
  titleText,
  showBanner,
  bannerText,
  bannerTitle,
  bannerAction,
}) => {
  const { fullName, isAuthenticated, signOut, profile } = useAuth()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const image = useProfilePicture(profile.userId)
  if (isLargerThan1000) {
    return (
      <Flex
        direction="column"
        top={0}
        zIndex={10}
        position="sticky"
        pointerEvents="all"
      >
        <Flex
          bg={[bgBehind ? 'rgba(255,255,255, 0.50)' : '#fff', '#fff']}
          borderRadius="0 0 10px 10px"
          h="4em"
          w="100%"
          alignItems="center"
          justifyContent={isAuthenticated ? 'space-between' : 'center'}
          p={defaultPadding}
        >
          {LeftComponent ? <LeftComponent /> : isAuthenticated && <Flex />}
          {!hideLogo && (
            <Link to="/">
              <Image src="/images/logo.png" w="4em" />
            </Link>
          )}
          {titleText !== '' && (
            <Text fontSize="larger" color={primaryColor} fontWeight="bold">
              {titleText}
            </Text>
          )}
          {isAuthenticated && (
            <AvatarIcon name={fullName} size="sm" src={image} />
          )}
        </Flex>
        {showBanner && (
          <Box p="5" onClick={bannerAction} zIndex={999}>
            <NotificationBanner title={bannerTitle} text={bannerText} />
          </Box>
        )}
      </Flex>
    )
  }
  return (
    <Flex
      direction="column"
      top={0}
      zIndex={10}
      position="sticky"
      pointerEvents="all"
    >
      <Flex
        bg={[bgBehind ? 'rgba(255,255,255, 0.50)' : '#fff', '#fff']}
        borderRadius="0 0 10px 10px"
        h="4em"
        w="100%"
        alignItems="center"
        justifyContent={isAuthenticated ? 'space-between' : 'center'}
        p={defaultPadding}
      >
        {LeftComponent ? (
          <LeftComponent />
        ) : (
          isAuthenticated && (
            <AvatarIcon name={fullName} size="sm" src={image} />
          )
        )}
        {!hideLogo && (
          <Link to="/">
            <Image src="/images/logo.png" w="4em" />
          </Link>
        )}
        {titleText !== '' && (
          <Text fontSize="larger" color={primaryColor} fontWeight="bold">
            {titleText}
          </Text>
        )}
        {isAuthenticated && (
          <IconButton
            aria-label="button"
            icon={<HamburgerIcon color="primary" />}
            bg="#ffffff"
            variant={'unstyled'}
            onClick={onOpen}
          />
        )}
        <Drawer isOpen={isOpen} onClose={onClose} size={'full'}>
          <DrawerOverlay />
          <DrawerContent w="100vw">
            <Flex
              alignItems="center"
              direction="row"
              justifyContent="center"
              paddingTop="3"
            >
              <Image src="/images/logo-small.png" />
              <DrawerCloseButton />
            </Flex>
            <DrawerHeader />

            <DrawerBody
              display="flex"
              flexDirection="column"
              flex={1}
              justifyContent="space-between"
            >
              <Flex direction="column" padding="10">
                <NavItem
                  text={t('dashboard')}
                  color={'primary'}
                  icon={'home'}
                  to={'/'}
                  isLargerThan1000={isLargerThan1000}
                />
                <NavItem
                  text={t('boosters')}
                  color={'primary'}
                  icon={'user-group'}
                  to={'/boosters'}
                  isLargerThan1000={isLargerThan1000}
                />
                <NavItem
                  text={t('profile')}
                  color={'primary'}
                  icon={'person'}
                  to={`/${profile.uniqueURLid}`}
                  isLargerThan1000={isLargerThan1000}
                />
                <NavItem
                  text={'Liidit'}
                  color={'primary'}
                  icon={'briefcase'}
                  to={'/leads'}
                />
              </Flex>
              <Flex direction="column" padding="10">
                {/* <SettingItem
                text={t('recoverPasswordPage')}
                color="#D67C00"
                iconColor="#D67C00"
                to="/recover-password"
              /> */}
                <SettingItem
                  text={t('resetPassword')}
                  color="#D67C00"
                  iconColor="#D67C00"
                  to="/forgot-password"
                />
                <SettingItem
                  text={t('logout')}
                  color="#D67C00"
                  iconColor="#D67C00"
                  onClick={signOut}
                />
                {/* <SettingItem
                text="Peru jäsenyys"
                color="#D67C00"
                iconColor="#D67C00"
                to="/recover-password"
              />
              <SettingItem
                text="Tietosuojakäytäntö"
                color="#D67C00"
                iconColor="#D67C00"
                to="/recover-password"
              /> */}
                <Flex paddingBottom="10" />
              </Flex>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Flex>
      {showBanner && (
        <Box p="5" onClick={bannerAction}>
          <NotificationBanner title={bannerTitle} text={bannerText} />
        </Box>
      )}
    </Flex>
  )
}

export default TopBar
