import { gql, useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { Group } from '../types/group'

const getGroupsQuery = () => gql`
  query {
    groups {
      _id
      name
    }
  }
`

const useGroups = () => {
  const [boostGroups, setBoostGroups] = useState<{ groups: Group[] }>()
  const { data } = useQuery(getGroupsQuery())

  useEffect(() => {
    setBoostGroups(data)
  }, [data])

  return { boostGroups }
}

export default useGroups
