import { Link as ChakraLink, LinkProps } from '@chakra-ui/layout'
import { Link } from 'react-router-dom'

interface Props extends LinkProps {
  to: string
}
const RouterLink: React.FC<Props> = ({ to, children, ...props }) => (
  <ChakraLink as={Link} to={to} {...props}>
    {children}
  </ChakraLink>
)

export default RouterLink
