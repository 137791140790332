import { Textarea, TextareaProps } from '@chakra-ui/react'
import { useField } from 'formik'
import { FC } from 'react'
import { BaseProps, FormControl } from '../form-control'

export type TextareaControlProps = BaseProps & {
  textareaProps?: TextareaProps
}

export const TextareaControl: FC<TextareaControlProps> = (
  props: TextareaControlProps,
) => {
  const { name, label, placeholder, textareaProps, ...rest } = props
  const [field] = useField(name)

  return (
    <FormControl name={name} label={label} {...rest}>
      <Textarea
        {...field}
        id={name}
        placeholder={placeholder}
        {...textareaProps}
      />
    </FormControl>
  )
}

export default TextareaControl
