import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import DefaultLayout from '../../components/Layout'
import useLeads from '../../hooks/useLeads'
import { Lead } from '../../types/lead'
import Meetings from './Meetings'
import NewMembers from './NewMembers'
import Trainings from './Trainings'

const Dashboard: React.FC = () => {
  const navigate = useNavigate()
  const { leads } = useLeads()
  const [newLeads, setNewLeads] = useState<Lead[]>([])

  useEffect(() => {
    if (leads) {
      const openLeads = leads.filter((lead) => lead.status === 'new')
      setNewLeads(openLeads)
    }
  }, [leads])

  return (
    <DefaultLayout
      showBanner={newLeads.length > 0}
      bannerText={`Sinulla on ${newLeads.length} hyväksymätöntä liidiä.`}
      bannerTitle={'Liidit'}
      bannerAction={() => navigate('/leads')}
    >
      <Meetings />
      <NewMembers />
      <Trainings />
    </DefaultLayout>
  )
}

export default Dashboard
