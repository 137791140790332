import { gql } from '@apollo/client'
import { objectToGQLSyntax } from '../../utils'

export const getTrainingsQuery = (args: any) => gql`
query {
  trainings(${objectToGQLSyntax(args)}) {
      ${trainingQueryProperties}
   }
  }
`

export const getTrainingQuery = (args: any) => gql`
query {
  training(${objectToGQLSyntax(args)}) {
      ${trainingQueryProperties}
   }
  }
`

export const trainingQueryProperties = `
    _id
    title
    description
    from
    to
    location
    speakers {
      firstName
      lastName
      speakerTopic
      uniqueURLid
    }
`
