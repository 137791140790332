import { Button, ButtonProps } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'

interface Props extends ButtonProps {
  to: string
  external?: boolean
}

const LinkButton: React.FC<Props> = ({ external, children, to, ...props }) =>
  (external ? (
    <a href={to} target="_blank" rel="noreferrer">
      <Button {...props}>{children}</Button>
    </a>
  ) : (
    <Link to={to}>
      <Button {...props}>{children}</Button>
    </Link>
  ))

export default LinkButton
