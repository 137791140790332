import { Group } from '../types/group'

const convertArrayToSelectOptions = (
  groupsOrTeams: Group[] | undefined /* eslint no-underscore-dangle: 0 */,
  value?: string,
) => {
  if (groupsOrTeams && groupsOrTeams.length > 0) {
    const selectOptions = groupsOrTeams.map((gt) => ({
      label: gt.name,
      value: value === 'name' ? gt.name : gt._id,
    }))
    return selectOptions
  }
  return []
}

export default convertArrayToSelectOptions
