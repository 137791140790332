import {
  ApolloError,
  DocumentNode,
  QueryHookOptions,
  useQuery,
} from '@apollo/client'

// eslint-disable-next-line func-style
export function useInternalQuery<T>(
  query: DocumentNode,
  dataKey: string,
  options: QueryHookOptions = {},
): { data?: T; error?: ApolloError; loading: boolean } {
  const { data, error, loading } = useQuery(query, options)
  if (loading || !data[dataKey]) return { error, loading }
  return {
    // @ts-ignore
    data: Array.isArray(data[dataKey])
      ? data[dataKey]
      : {
          ...data[dataKey],
          // eslint-disable-next-line no-underscore-dangle
          id: data[dataKey]._id,
        },
    error,
    loading,
  }
}
