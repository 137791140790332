import { Flex } from '@chakra-ui/layout'
import gql from 'graphql-tag'
import {
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import { green, primaryColor, red } from '../theme/styles'
import Card from './basic/Card'
import BasicButton from './Buttons/BasicButton'
import Modal from './basic/Modal'
import Input from './basic/Input'
import { Lead } from '../types/lead'
import { useSnackbar } from '../hooks/useSnackbar'
import { receiverLeadQueryProperties } from '../pages/Lead/query'

type Props = {
  lead: Lead
  refetch: () => void
}

const leadMutation = gql`
  mutation updateOneLead($set: LeadUpdateInput!, $query: LeadQueryInput) {
    updateOneLead(set: $set, query: $query) {
      ${receiverLeadQueryProperties}
    }
  }
`

const NewLead: React.FC<Props> = ({ lead, refetch }) => {
  const [rejectModalOpen, setRejectModalOpen] = useState<boolean>(false)
  const [description, setDescription] = useState<string>('')
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { sendSnackbarMessage } = useSnackbar()
  const [updateLead] = useMutation(leadMutation)

  const changeStatus = async ({ status }: { status: string }) => {
    const rejected = status === 'rejected'
    try {
      await updateLead({
        variables: {
          set: {
            status,
            cancelDescription: status !== 'open' ? description : null,
          },
          // eslint-disable-next-line
          query: { _id: lead._id },
        },
      })
      sendSnackbarMessage(
        rejected ? t('lead.leadRejected') : t('lead.leadAccepted'),
        rejected ? { messageType: 'error' } : {},
      )

      refetch()
    } catch (e: any) {
      // eslint-disable-next-line
      sendSnackbarMessage(t('somethingWentWrong'), { messageType: 'error' })
    }
  }

  return (
    <Card m="5">
      <Flex direction="column" justifyContent="space-between" p="5">
        <Text color={primaryColor} fontSize="larger" fontWeight="bold">
          {lead.title}
        </Text>
        <Text color={primaryColor}>{lead.customerDescription}</Text>
        <Flex direction="row">
          <BasicButton
            color="#ffffff"
            textColor={primaryColor}
            fontSize={['sm', 'md', 'lg']}
            hasBorder
            text="Hylkään"
            onClick={() => setRejectModalOpen(true)}
          />
          <BasicButton
            color={green}
            textColor={'#ffffff'}
            fontSize={['sm', 'md', 'lg']}
            text="Hyväksyn"
            onClick={async () => {
              await changeStatus({ status: 'open' })
              // eslint-disable-next-line no-underscore-dangle
              navigate(`${lead._id}`, { replace: true })
            }}
          />
          <Modal
            isOpen={rejectModalOpen}
            onClose={() => setRejectModalOpen(!rejectModalOpen)}
          >
            <Flex>
              <ModalOverlay />
              <ModalContent top="20vh" m="10">
                <ModalHeader>Hylkää liidi (Title)</ModalHeader>
                <ModalBody>
                  <Text color={primaryColor}>{lead.customerDescription}</Text>
                </ModalBody>
                <Input
                  m="5"
                  placeholder="Perustele"
                  onChange={(e: any) => {
                    setDescription(e.target.value)
                  }}
                />
                <Flex direction="row" p="5">
                  <BasicButton
                    color="#ffffff"
                    textColor={primaryColor}
                    hasBorder
                    text="Peruuta"
                    onClick={() => setRejectModalOpen(false)}
                  />
                  <BasicButton
                    color={red}
                    textColor={'#ffffff'}
                    text="Hylkää"
                    onClick={() => {
                      changeStatus({ status: 'rejected' })
                      setRejectModalOpen(!rejectModalOpen)
                    }}
                  />
                </Flex>
              </ModalContent>
            </Flex>
          </Modal>
        </Flex>
      </Flex>
    </Card>
  )
}

export default NewLead
