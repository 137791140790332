import React, { useState } from 'react'
import { Flex, Text, IconButton, Tooltip, Box } from '@chakra-ui/react'
import { FieldArray, useField } from 'formik'
import { useTranslation } from 'react-i18next'
import { string } from 'yup'
import { CheckIcon, CloseIcon } from '@chakra-ui/icons'
import InputRightElement from '../../Inputs/InputRightThingy'
import MultiInput from '../../Inputs/MultiInput'
import IconText from '../../../IconText'

interface Props {
  name: string
}

const ProfileEmailArrayForm: React.FC<Props> = ({ name }) => {
  const [{ value: emails }] = useField(name)

  return (
    <FieldArray name={name}>
      {({ push, remove }) => (
        <div>
          {emails
            .map((email: any, index: number) => (
              <Flex
                key={email.address}
                alignItems="center"
                justifyContent="space-between"
              >
                <IconText color="primary" icon={email.primary && 'check'}>
                  <Text paddingLeft={3}>{email.address}</Text>
                </IconText>

                <IconButton
                  variant="none"
                  aria-label="remove email"
                  onClick={() => remove(index)}
                >
                  <CloseIcon />
                </IconButton>
              </Flex>
            ))
            .sort((a: any, b: any) => Number(b.primary) - Number(a.primary))}
          <EmailInput name={name} push={push} />
        </div>
      )}
    </FieldArray>
  )
}

const EmailInput: React.FC<any> = ({ push, name }) => {
  const [isDefault, setIsDefault] = useState(false)
  const [{ value: emails }, { error }, { setError }] = useField(name)
  const hasPrimaryEmail = emails?.find((item: any) => item.primary === true)
  const { t } = useTranslation()

  return (
    <MultiInput
      placeholder={t('email')}
      error={error}
      onSubmit={(email: string) => {
        try {
          const validation = string().required().email()
          if (validation.validateSync(email)) {
            push({ address: email, primary: isDefault })
          }
        } catch {
          setError(t('invalidEmail'))
        }
      }}
    >
      <Tooltip label={t('primaryEmail')}>
        <Box zIndex={0}>
          <InputRightElement>
            <IconButton
              color={isDefault && !hasPrimaryEmail ? 'secondary' : 'primary'}
              onClick={() => {
                if (!hasPrimaryEmail) {
                  setIsDefault(!isDefault)
                }
              }}
              aria-label="primary email"
              variant="none"
              margin={0}
            >
              <CheckIcon />
            </IconButton>
          </InputRightElement>
        </Box>
      </Tooltip>
    </MultiInput>
  )
}

export default ProfileEmailArrayForm
