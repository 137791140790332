import React from 'react'
import { gql, useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import LeadForm from '../../components/formik/forms/lead/LeadForm'
import Layout from '../../components/Layout'
import { useAuth } from '../../hooks'
import { useSnackbar } from '../../hooks/useSnackbar'
import { Lead } from '../../types/lead'

// TODO: add to query.tsx file
const leadMutation = gql`
  mutation insertOneLead($data: LeadInsertInput!) {
    insertOneLead(data: $data) {
      _id
      probability
      customerDescription
      customerContact
      receiverId
      userId
      team {
        _id
      }
    }
  }
`

type Props = {}

const NewLead: React.FC<Props> = () => {
  const [insertLead] = useMutation(leadMutation)

  const { t } = useTranslation()
  const { sendSnackbarMessage } = useSnackbar()
  const navigate = useNavigate()
  const { sendSnackbarMessage: sendErrorSnackBar } = useSnackbar()
  const { profile: data } = useAuth()

  const handleSubmit = async (
    values: Partial<Lead>,
    actions: any,
    leadReceiver: string,
  ) => {
    const lead: Partial<Lead> = {
      title: values.title,
      customer: values.customer,
      customerContact: values.customerContact,
      customerDescription: values.customerDescription,
      probability: values.probability,
    }

    try {
      await insertLead({
        variables: {
          data: {
            ...lead,
            userId: data.userId,
            team: { link: values.team?.name.value },
            receiverId: leadReceiver,
          },
        },
      })
      sendSnackbarMessage(t('leadSendSuccess'))
      navigate('/leads', { replace: true })
    } catch (e: any) {
      sendErrorSnackBar(t('leadSendFailure'))
    } finally {
      actions.setSubmitting(false)
    }
  }

  return (
    <Layout backButton titleText={'Välitä uusi liidi'}>
      <LeadForm onSubmit={handleSubmit} />
    </Layout>
  )
}

export default NewLead
