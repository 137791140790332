export function pickFromArrByCondition<T>(
  arr?: T[],
  condition?: (ent: T) => boolean,
): [result: T | null, rest: T[] | null] {
  if (arr && condition) {
    const copy = [...arr]
    const picked = copy.findIndex(condition)
    if (picked > -1) {
      const [found] = copy.splice(picked, 1)
      return [found, copy]
    }
  }
  return [null, null]
}

export const objectToGQLSyntax = (objToChange: any): string => {
  if (typeof objToChange === 'string') return objToChange

  const result = Object.keys(objToChange).reduce((acc, currentKey, index) => {
    const currentValue = objToChange[currentKey]
    const currentValueIsObject = typeof currentValue === 'object'

    return `${acc} ${index ? ',' : ''} ${currentKey}: ${
      // eslint-disable-next-line no-nested-ternary
      currentValueIsObject
        ? `{${objectToGQLSyntax(currentValue)}}`
        : currentKey === 'sortBy' || currentKey === 'limit'
        ? currentValue // The sort keys are not strings in gql
        : `"${objToChange[currentKey]}"`
    } `
  }, '')

  return result
}
