import React from 'react'
import { Box, IconButton, Button, Flex } from '@chakra-ui/react'
import { FieldArray, useField } from 'formik'
import { CloseIcon } from '@chakra-ui/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { defaultPadding } from '../../../theme'
import Card from '../../basic/Card'

interface Props {
  name: string
  createObject: any
  children: any
  buttonText?: string
  min1?: boolean
}

const FormikMultiInput: React.FC<Props> = ({
  name,
  children,
  createObject,
  buttonText,
  min1,
}) => {
  const [_, meta] = useField(name)
  const value = meta.value || []
  let primaryCompanyIndex = 0
  if (name === 'companies') {
    value.forEach((company: any, i: number) => {
      if (company.primary === true) {
        primaryCompanyIndex = i
      }
    })
  }
  return (
    <FieldArray name={name}>
      {({ push, remove }) => (
        <Box>
          {(value || []).map((values: any, index: number) => (
            <Card
              title={`# ${index + 1}`}
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              marginY={defaultPadding}
              ActionComponent={
                <IconButton
                  variant="none"
                  aria-label="Remove"
                  disabled={min1 && value.length <= 1}
                  onClick={() => remove(index)}
                >
                  <CloseIcon />
                </IconButton>
              }
            >
              {children({ index, value, primaryCompanyIndex })}
            </Card>
          ))}
          <Flex justifyContent="center">
            <Button
              isFullWidth
              maxW={400}
              mt={0}
              onClick={() => push(createObject)}
            >
              {buttonText || <FontAwesomeIcon icon="plus" />}
            </Button>
          </Flex>
        </Box>
      )}
    </FieldArray>
  )
}

export default FormikMultiInput
