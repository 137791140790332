import { IsEmail, IsNotEmpty, MinLength } from 'class-validator'

export class ForgotPassword {
  @IsNotEmpty({ message: 'An email is required' })
  @IsEmail()
  readonly email!: string
}

export class NewPassword {
  @IsNotEmpty({ message: 'Password is required' })
  @MinLength(6, { message: 'Your password must be at least 6 characters' })
  readonly password!: string
}
