import { Box, BoxProps, Heading, Flex } from '@chakra-ui/react'
import { ReactElement } from 'react'

interface Props extends Omit<BoxProps, 'title'> {
  title?: ReactElement | string
  variant?: 'primary' | 'secondary' | 'gray'
  ActionComponent?: any
}

const Card: React.FC<Props> = ({
  variant,
  ActionComponent,
  children,
  title,
  ...props
}) => (
  <Box
    bg={variant || 'white'}
    boxShadow={['', 'md']}
    rounded={['', 'lg']}
    overflow="hidden"
    p={[4, 4]}
    {...props}
  >
    <Flex justifyContent="space-between" alignItems="center">
      {title && <Heading fontSize="xl">{title}</Heading>}
      {ActionComponent}
    </Flex>
    <Flex flexDir="column">{children}</Flex>
  </Box>
)

export default Card
