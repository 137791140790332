import { Flex, Text, useMediaQuery } from '@chakra-ui/react'
import { Speaker } from '../types/training'
import AvatarIcon from './basic/AvatarIcon'
import useProfilePicture from '../hooks/useProfilePicture'

interface Props {
  speaker: Speaker
}

const SpeakerItem: React.FC<Props> = ({ speaker }) => {
  const [isLargerThan1000] = useMediaQuery('(min-width: 1000px)')
  const image = useProfilePicture(speaker.uniqueURLid, true)

  return (
    <Flex
      direction={isLargerThan1000 ? 'column' : 'row'}
      w={isLargerThan1000 ? '50%' : '100%'}
      paddingX="5"
      paddingY="5"
      alignItems="center"
      justifyContent="center"
    >
      <AvatarIcon
        src={image}
        name={`${speaker.firstName} ${speaker.lastName}`}
        size={isLargerThan1000 ? 'xl' : 'lg'}
        mb="1"
        mr="5"
      />
      <Flex
        direction="column"
        alignItems="center"
        w={isLargerThan1000 ? '50' : '100'}
      >
        <Text
          fontWeight="bold"
          color="orange.400"
          alignSelf={isLargerThan1000 ? 'center' : 'flex-start'}
        >
          {speaker.firstName} {speaker.lastName}
        </Text>
        <Text>{speaker.speakerTopic}</Text>
      </Flex>
    </Flex>
  )
}
export default SpeakerItem
