import { Box, Flex, Image, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import { Link } from 'react-router-dom'
import { Meeting } from '../types/meeting'
import Card from './basic/Card'
import FAIcon from './FAIcon'

interface Props {
  data: Meeting
  variant?: 'primary' | 'secondary' | 'gray'
  small?: boolean
}

const MeetingCard: React.FC<Props> = ({ data, variant, small }) => {
  const { t } = useTranslation()

  return (
    <Link to={`/meetings/${data.uniqueURLid}`}>
      <Card variant={variant || 'primary'} p={0}>
        {!small && (
          <Image src={`/images/${data.meetingType}_placeholder.png`} />
        )}
        <Flex
          color="white"
          justifyContent="space-between"
          alignItems="center"
          m={0}
          py={2}
          px={4}
        >
          <Flex alignItems="center">
            <FAIcon color="white" icon="video" />
            <Flex flexDir="column" ml={4}>
              {t(`${data.meetingType}Meeting`)}
              <Text fontWeight="bold" p={0} m={0}>
                {`Boost ${data.group.name}`}
              </Text>
            </Flex>
          </Flex>
          <Box textAlign="end">
            {format(new Date(data.from), 'dd.MM.yyyy')}
            <Text fontWeight="bold" p={0} m={0}>
              {`${format(new Date(data.from), 'HH.mm')}`} -{' '}
              {`${format(new Date(data.to), 'HH.mm')}`}
            </Text>
          </Box>
        </Flex>
      </Card>
    </Link>
  )
}
export default MeetingCard
