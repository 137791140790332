import { useLayoutEffect, useState } from 'react'
import { IMAGE_ENV, BLOB_STORAGE_ENDPOINT, SPEAKER_IMAGE_ENV } from '../constant'

const useProfilePicture = (userId: string, speaker: boolean = false) => {
  const [image, setImage] = useState<string>('')
  const storageEnv: string = speaker ? SPEAKER_IMAGE_ENV : IMAGE_ENV

  useLayoutEffect(() => {
    fetch(`${BLOB_STORAGE_ENDPOINT}/${storageEnv}/${userId}?time=${Date.now()}`)
      .then((picData) => {
        if (picData.status === 200) {
          return picData.text()
        }
        return ''
      })
      .then((pic) => {
        setImage(pic)
      })
  }, [userId])

  return image
}

export default useProfilePicture
