import { Form, Formik } from 'formik'
import { Flex } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { FormikInput, SubmitButton } from '../../components/formik/Inputs'
import { FormikPasswordInput } from '../../components/formik/Inputs/PasswordInput'
import RouterLink from '../../components/basic/RouterLink'
import { AuthLoginProps } from '../../types/auth'
import LinkButton from '../../components/Buttons/LinkButton'
import { formikErrorLocalisations } from '../../lib/i18n'

formikErrorLocalisations()

const validationSchema = yup.object().shape({
  email: yup.string().required(),
  password: yup.string().required(),
})

const LoginForm: React.FC<AuthLoginProps> = ({ onSubmit }) => {
  const { t } = useTranslation()

  const initialValues = {
    email: '',
    password: '',
  }

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validateOnChange={false}
      validationSchema={validationSchema}
    >
      <Form>
        <FormikInput type="email" placeholder={t('email')} name="email" />
        <FormikPasswordInput placeholder={t('password')} name="password" />

        <Flex flexDir="row" justifyContent="flex-end" mt={5} mb={5}>
          <RouterLink to="/forgot-password">
            {t('logInPage.forgotPassword')}
          </RouterLink>
          <br />
        </Flex>

        <SubmitButton> {t('logInPage.login')} </SubmitButton>
        <LinkButton to="/register" variant="outline" isFullWidth>
          {t('register')}
        </LinkButton>
      </Form>
    </Formik>
  )
}

export default LoginForm
