import { Box, BoxProps, Flex } from '@chakra-ui/react'

interface Props extends Omit<BoxProps, 'title'> {}

const FloatingBar: React.FC<Props> = ({ children }) => (
  <Flex flexDir="row" alignItems="center" justifyContent="center">
    <Box
      pos="fixed"
      maxWidth={768}
      w={'100%'}
      bg="white"
      borderTopRadius={[0, 8]}
      py={2}
      bottom={0}
      z-index={1}
    >
      <Flex flexDir="row" alignItems="center" justifyContent="center">
        {children}
      </Flex>
    </Box>
  </Flex>
)

export default FloatingBar
