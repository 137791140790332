import { Flex } from '@chakra-ui/layout'
import { Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { Lead, LeadStatus } from '../types/lead'
import StatusBadge from './basic/Badge'
import Card from './basic/Card'

type Props = {
  status: string
  lead: Lead
}

const LeadCard: React.FC<Props> = ({ status, lead }: Props) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  return (
    <Card
      m="5"
      p="5"
      onClick={() => {
        if (lead.status === LeadStatus.Open) {
          // eslint-disable-next-line no-underscore-dangle
          navigate(`${lead._id}`, { replace: true })
        }
      }}
    >
      <Flex justifyContent="space-between">
        <Text fontSize={['sm', 'md', 'lg']}>{lead.title}</Text>
        <StatusBadge text={status}>{t(`lead.status.${status}`)}</StatusBadge>
      </Flex>
    </Card>
  )
}

export default LeadCard
