import { Flex } from '@chakra-ui/layout'
import { Text } from '@chakra-ui/react'
import { useState } from 'react'
import { primaryColor } from '../theme/styles'
import FAIcon from './FAIcon'

type Props = {
  title: string
  text: string
}

const NotificationBanner: React.FC<Props> = ({ title, text }) => {
  const [showBanner] = useState<boolean>(true)

  return (
    <Flex
      backgroundColor={primaryColor}
      padding="4"
      borderRadius="6"
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      color="#ffffff"
      display={showBanner ? 'flex' : 'none'}
    >
      <Flex direction="row" alignSelf="flex-start" alignItems="center">
        <FAIcon icon="circle-exclamation" color="#ffffff" size="2x" />
        <Flex direction="column" justifyContent="center" pl="5">
          <Text fontWeight="bold" m="0">
            {title}
          </Text>
          <Text m="0" fontSize={['sm', 'md', 'lg']}>
            {text}
          </Text>
        </Flex>
      </Flex>
      {/* <FAIcon
        icon="close"
        color="#ffffff"
        size="2x"
        onClick={() => hideBanner()}
      /> */}
    </Flex>
  )
}

export default NotificationBanner
