import { Box, Image } from '@chakra-ui/react'

type Props = {
  image?: string
}

/* eslint-disable */
const BackgroundImage = ({ image }: Props) => (
  <Box
    h={[300, '42vh']}
    bgPosition="center"
    bgSize="auto"
    backgroundRepeat="no-repeat"
    background={
      image === ''
        ? 'linear-gradient(90deg, rgba(41,50,98,1) 0%, rgba(0,78,200,1) 100%)'
        : '#ffffff'
    }
  >
    {image !== '' ? (
      <Image src={image} h={[300, '42vh']} w={'100%'} backgroundSize="cover" />
    ) : null}
  </Box>
)
/* eslint-enable */

export default BackgroundImage
