import { Form, Formik } from 'formik'
import createValidator from 'class-validator-formik'
import { useTranslation } from 'react-i18next'
import { FormikInput, SubmitButton } from '../../components/formik/Inputs'
import { ForgotPassword } from '../../validation/forgotPassword'
import CenteredLayout from '../../components/Layout/CenteredLayout'
import { useAuth } from '../../hooks'

const ForgotPasswordPage: React.FC = () => {
  const { t } = useTranslation()
  const { forgotPassword } = useAuth()

  const handleSubmit = async ({ email }: { email: string }) => {
    await forgotPassword({ email })
  }

  return (
    <CenteredLayout title={t('forgotPasswordPage')}>
      <Formik
        initialValues={{ email: '' }}
        onSubmit={handleSubmit}
        validate={createValidator(ForgotPassword)}
      >
        <Form>
          <FormikInput name="email" type="email" placeholder={t('email')} />
          <SubmitButton isFullWidth> {t('resetPassword')} </SubmitButton>
        </Form>
      </Formik>
    </CenteredLayout>
  )
}

export default ForgotPasswordPage
