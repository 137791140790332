import {
  Flex,
  Heading,
  Text,
  Tag,
  AvatarBadge,
  Input,
  Box,
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Member } from '../../../types/member'
import useProfilePicture from '../../../hooks/useProfilePicture'
import AvatarIcon from '../../../components/basic/AvatarIcon'

interface Props {
  data: Member
  showImageUpload?: boolean
  imageBase?: string | undefined
  handleImageChange?: (event: any) => void
}

const ProfileHeading: React.FC<Props> = ({
  data,
  showImageUpload,
  imageBase,
  handleImageChange,
}) => {
  const fullName = `${data?.firstName} ${data?.lastName}`
  const primaryOrg = data?.companies?.find((comp) => comp.primary)
  const image = useProfilePicture(data.userId)

  return (
    <Flex
      flexDir="column"
      alignItems="center"
      justifyContent="center"
      marginTop="-20"
    >
      <AvatarIcon size="2xl" src={imageBase || image} name={fullName}>
        {showImageUpload && (
          <label htmlFor="profile-image-upload">
            <AvatarBadge
              borderWidth="0px"
              color="primary"
              bg="secondary"
              cursor="pointer"
              boxSize="0.8em"
              padding={2}
            >
              <Input
                onChange={handleImageChange}
                type="file"
                display="none"
                accept="image/*"
                id="profile-image-upload"
              />
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <Box cursor="pointer">
                <FontAwesomeIcon style={{ width: '100%' }} icon="plus" />
              </Box>
            </AvatarBadge>
          </label>
        )}
      </AvatarIcon>
      <Heading mt={4} as="h1">
        {fullName}
      </Heading>
      {primaryOrg && <Text variant="caption">{primaryOrg.name}</Text>}

      {data.boostGroup?.city ? (
        <Tag my={4} color="primary">
          {data.boostGroup?.city}
        </Tag>
      ) : null}
      {data.serviceTopic && (
        <Tag my={4} color="primary">
          {data.serviceTopic}
        </Tag>
      )}
    </Flex>
  )
}

export default ProfileHeading
