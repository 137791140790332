import React from 'react'
import { Box, InputRightElement, IconButton } from '@chakra-ui/react'
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import { FormikInput } from '.'
import { InputControlProps } from './FormikInput'

export const FormikPasswordInput: React.FC<InputControlProps> = (props) => {
  const [show, setShow] = React.useState(false)
  const handleClick = () => setShow(!show)

  return (
    <Box>
      <FormikInput type={show ? 'text' : 'password'} {...props}>
        <InputRightElement top="1.5" right="1">
          <IconButton
            onClick={handleClick}
            variant="none"
            aria-label="password"
            icon={!show ? <ViewIcon /> : <ViewOffIcon />}
          />
        </InputRightElement>
      </FormikInput>
    </Box>
  )
}
