import Resizer from 'react-image-file-resizer'

export const resizeImage = async (file: any, sizeX?: number, sizeY?: number) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      150,
      150,
      'WEBP',
      80,
      0,
      (uri: string | Blob | File | ProgressEvent<FileReader>) =>
        resolve(uri as string),
      'base64',
      sizeX || 100,
      sizeY || 100,
    )
  })
