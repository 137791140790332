export interface Lead {
  _id: number
  title: string
  findersFee: number
  cancelDescription: string
  sellingCoverage: number
  receiver: string
  team: { name: { label: string, value: string } }
  customer: string
  customerContact: string
  customerDescription: string
  probability: number
  closingDate: Date
  status: string
  id: string
}

export type SelectOption = {
  label: string
  value: string
}

export enum LeadStatus {
  New = 'new',
  Rejected = 'rejected',
  Open = 'open',
  Won = 'won',
  Lost = 'lost',
}
