import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid } from '@chakra-ui/react'
import ProfileCard from './ProfileCard'
import { Member } from '../types/member'

interface Props {
  data: Member[]
  navigateToProfile?: boolean
  onClick?: (name: string, userId: string) => void
}
const MemberList: React.FC<Props> = ({
  data,
  onClick = () => {},
  navigateToProfile = false,
}) => {
  const navigate = useNavigate()
  return (
    <Grid gap="2">
      {data.map((member) => (
        <ProfileCard
          row
          data={member}
          key={member.userId}
          onClick={
            navigateToProfile
              ? () => navigate(`/${member.uniqueURLid}`)
              : () => onClick(`${member.firstName} ${member.lastName}`, member.userId)
          }
        />
      ))}
    </Grid>
  )
}

export default MemberList
