import { Flex, Box, Grid, GridItem } from '@chakra-ui/react'
import React from 'react'
import { format } from 'date-fns'
import { fi } from 'date-fns/locale'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { defaultPadding } from '../../../theme'
import { Meeting } from '../../../types/meeting'

interface Props {
  data: Meeting
  variant: string
}

const Details: React.FC<Props> = ({ data, variant }) => {
  const { t } = useTranslation()

  return (
    <Box>
      <Grid templateColumns="repeat(1, 1fr)">
        <Flex direction="row">
          <ContactListItem icon="clock" variant={variant}>
            {format(new Date(data.from), 'cccc,  dd.MM.yyyy', { locale: fi })}
          </ContactListItem>
          <ContactListItem variant={variant}>
            {`${format(new Date(data.from), 'HH.mm')}`} -{' '}
            {`${format(new Date(data.to), 'HH.mm')}`}
          </ContactListItem>
        </Flex>
        <ContactListItem icon="door-open" variant={variant}>
          {data.location}
        </ContactListItem>
        <ContactListItem icon="user-group" variant={variant}>
          {`${data.group.members.length} ${t('participants')}`}
        </ContactListItem>
      </Grid>
    </Box>
  )
}

const ContactListItem: React.FC<{ icon?: any; variant: string }> = ({
  icon,
  children,
  variant,
}) => (
  <GridItem>
    <Flex p={defaultPadding} alignItems="center">
      {icon && <FontAwesomeIcon style={{ color: variant }} icon={icon} size="lg" />}
      <Box ml={5}>{children}</Box>
    </Flex>
  </GridItem>
)

export default Details
