import * as yup from 'yup'

export const formValidationSchema = yup.object().shape({
  userId: yup.string().required(),
  firstName: yup.string().required().min(2, 'tooShortMoreThanTwo'),
  lastName: yup.string().required().min(2, 'tooShortMoreThanTwo'),
  phoneNumber: yup.string().required().min(8),
  linkedin: yup.string().nullable().url(),
  bookMeetingURL: yup.string().nullable().url('validUrl'),
  facebook: yup.string().nullable().url(),
  twitter: yup.string().nullable().url(),
  jobTitle: yup.string(),
  phrase: yup.string().min(4, 'tooShortMoreThanFour'),
  detailedServicesPitch: yup.string().nullable().min(5),
  services: yup.string().min(4, 'tooShortMoreThanFour'),
  aboutMe: yup.string().min(4, 'tooShortMoreThanFour'),
  serviceTopic: yup.string().nullable().min(4, 'tooShortMoreThanFour'),
  emails: yup
    .array()
    .min(1)
    .of(
      yup.object().shape({
        address: yup.string().required('requiredField'),
        primary: yup.bool(),
      }),
    ),
  companies: yup
    .array()
    .min(1)
    .of(
      yup.object().shape({
        name: yup.string().required().min(4, 'tooShortMoreThanFour'),
        logoUrl: yup.string().min(4),
        companyId: yup
          .string()
          .required('requiredField')
          .nullable()
          .min(4, 'tooShortMoreThanFour'),
      }),
    ),
  locations: yup.array().of(
    yup.object().shape({
      address: yup
        .string()
        .required('requiredField')
        .min(4, 'tooShortMoreThanFour'),
      city: yup.string().min(4),
      zipcode: yup
        .string()
        .required('requiredField')
        .min(4, 'tooShortMoreThanFour'),
    }),
  ),
  serviceProducts: yup.array().of(
    yup.object().shape({
      headline: yup.string().min(4, 'tooShortMoreThanFour'),
      description: yup
        .string()
        .required('requiredField')
        .min(4, 'tooShortMoreThanFour'),
    }),
  ),
  cases: yup.array().of(
    yup.object().shape({
      type: yup.string().min(4, 'tooShortMoreThanFour'),
      body: yup.string().min(4, 'tooShortMoreThanFour'),
      headline: yup.string().min(4, 'tooShortMoreThanFour'),
      link: yup.string().nullable().url('validUrl'),
    }),
  ),
})
