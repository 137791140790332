/* eslint-disable */
export const IMAGE_ENV = 'testi'
export const ENVIRONMENT_ID = 'boost-dev-otzuh'
export const BLOB_STORAGE_ENDPOINT = 'https://bg001docs.blob.core.windows.net'
export const SPEAKER_IMAGE_ENV = 'productionspeaker'
export const PRODUCTION = false
/* eslint-enable */

// IMAGE_ENV = ['testi', 'production']
// ENVIRONMENT_ID = ['boost-dev-otzuh', 'boost-prod-lyhxw']
