import { Box } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { FormikInput } from '../../Inputs'
import FormikMultiInput from '../../Inputs/FormikMultiInput'

const ProfileCompaniesArrayForm: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Box>
      <FormikMultiInput
        createObject={{
          name: '',
          primary: '',
          companyId: '',
          companyAccountNumber: '',
        }}
        buttonText={t('addCompany')}
        name="companies"
        min1
      >
        {({ index, value, primaryCompanyIndex }: any) => (
          <>
            <FormikInput
              name={`companies.${index}.name`}
              placeholder={t('companyNamePlaceholder')}
              isDisabled={index === primaryCompanyIndex}
            />
            <FormikInput
              name={`companies.${index}.companyId`}
              placeholder={t('companyIdPlaceholder')}
              isDisabled={index === primaryCompanyIndex}
            />
            <FormikInput
              name={`companies.${index}.companyAccountNumber`}
              placeholder={t('companyAccountNumberPlaceholder')}
              isDisabled={index === primaryCompanyIndex}
            />
            <FormikInput
              name={`companies.${index}.primary`}
              label={t('primaryPlaceholder')}
              type="boolean"
              otherValues={value}
              index={index}
              isDisabled={index === primaryCompanyIndex}
            />
          </>
        )}
      </FormikMultiInput>
    </Box>
  )
}

export default ProfileCompaniesArrayForm
