import { Flex } from '@chakra-ui/layout'
import { Center } from '@chakra-ui/react'
import Layout from '.'
import Card from '../basic/Card'
import BackButton from '../Buttons/BackButton'

interface Props {
  title?: string
  noBackButton?: boolean
  showNav?: boolean
  hideLogo?: boolean
}

const CenteredLayout: React.FC<Props> = ({
  title,
  children,
  noBackButton,
  showNav = false,
  hideLogo = true,
}) => (
  <Layout
    display="flex"
    minH={['100vh']}
    justifyContent="center"
    alignItems="center"
    contentBg={['#ffffff', 'bg']}
    showNav={showNav}
    hideLogo={hideLogo}
  >
    <Center bg={'#ffffff'}>
      <Card
        maxW="600"
        title={
          <Flex alignItems="center" marginBottom={6}>
            {!noBackButton && <BackButton marginRight="0.6em" />} {title}
          </Flex>
        }
      >
        {children}
      </Card>
    </Center>
  </Layout>
)

export default CenteredLayout
