import { useLocation, Navigate } from 'react-router'
import { useEffect } from 'react'
import { useAuth } from '../hooks'
import Loader from './Loader'
import { useTag } from '../lib/tag'

const ProtectedRoute: React.FC<{ children: any }> = ({ children }) => {
  const { isAuthenticated, profile, authLoading } = useAuth()
  const { createEvent } = useTag()

  const location = useLocation()

  useEffect(() => {
    createEvent('pageview', location.pathname)
  }, [location])

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  if (authLoading) {
    return <Loader />
  }

  if (!profile) {
    return <Navigate to="/profile/edit" />
  }

  return children
}

export default ProtectedRoute
