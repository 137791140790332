import { Heading, Text } from '@chakra-ui/react'
import React from 'react'
import Card from '../../../components/basic/Card'
import { MemberServiceProducts } from '../../../types/member'

type Props = {
  data: MemberServiceProducts
}

const ServiceHighlight: React.FC<Props> = ({ data }) => (
  <Card my={2}>
    <Heading size="sm"> {data.headline}</Heading>
    <Text>{data.description}</Text>
  </Card>
)

export default ServiceHighlight
