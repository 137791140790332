import { Box } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { FormikInput } from '../../Inputs'
import FormikMultiInput from '../../Inputs/FormikMultiInput'

const ProfileCasesArrayForm: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Box>
      <FormikMultiInput
        createObject={{
          type: '',
          body: '',
          headline: '',
          link: '',
        }}
        buttonText={t('addCases')}
        name="cases"
      >
        {({ index }: any) => (
          <>
            <FormikInput
              name={`cases.${index}.type`}
              placeholder={t('typePlaceholder')}
            />
            <FormikInput
              name={`cases.${index}.body`}
              placeholder={t('bodyPlaceholder')}
            />
            <FormikInput
              name={`cases.${index}.headline`}
              placeholder={t('headlinePlaceholder')}
            />
            <FormikInput
              name={`cases.${index}.link`}
              placeholder={t('linkPlaceholder')}
            />
          </>
        )}
      </FormikMultiInput>
    </Box>
  )
}

export default ProfileCasesArrayForm
