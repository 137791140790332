import { Flex, Heading, Text, Tag } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'
import useProfilePicture from '../hooks/useProfilePicture'
import { Member } from '../types/member'
import AvatarIcon from './basic/AvatarIcon'
import Card from './basic/Card'

interface Props {
  data: Member
  row?: boolean
  onClick: () => void
}

const ProfileCard: React.FC<Props> = ({ data, row, onClick }) => {
  const fullName = `${data?.firstName} ${data?.lastName}`
  const primaryOrg = data?.companies?.find((comp) => comp.primary)
  const image = useProfilePicture(data.userId)

  const truncateString = (string: string | undefined) => {
    if (string && string.length > 50) {
      return `${string.substring(0, 50)}...`
    }
    return string
  }

  if (row) {
    return (
      <Card h={110} onClick={onClick} style={{ cursor: 'pointer' }}>
        <Flex flexDir={'row'} alignItems="center" alignSelf={'flex-start'}>
          <AvatarIcon size="lg" src={image} name={fullName} />
          <Flex
            flexDir="column"
            alignItems="center"
            justifyContent={'flex-start'}
          >
            <Flex
              flexDir="row"
              alignItems="center"
              justifyContent={'flex-start'}
              w={'100%'}
              ml={7}
            >
              <Heading size="sm" alignSelf={'center'} mr={1}>
                {fullName}
              </Heading>
              {primaryOrg && (
                <Text variant="caption" fontSize={10}>
                  {primaryOrg.name || ''}
                </Text>
              )}
            </Flex>
            <Flex
              flexDir="row"
              alignItems="center"
              justifyContent={'flex-start'}
              w={'100%'}
              ml={7}
            >
              {data.serviceTopic && (
                <Tag
                  fontSize={10}
                  fontWeight="bold"
                  color="primary"
                  mr={1}
                  p={2}
                >
                  {truncateString(data.serviceTopic)}
                </Tag>
              )}
            </Flex>
            <Text
              color="primary"
              fontWeight="bold"
              fontSize="xs"
              alignSelf={'flex-start'}
              ml={4}
            >
              {data.boostGroup && `Boost ${data.boostGroup.name}`}
            </Text>
          </Flex>
        </Flex>
      </Card>
    )
  }
  return (
    <Link to={`/${data.uniqueURLid}`}>
      <Card h={270}>
        <Flex flexDir={'column'} alignItems="center" justifyContent={'center'}>
          <AvatarIcon size="lg" src={image} name={fullName} />
          <Flex
            flexDir={'column'}
            justifyContent="center"
            alignItems="center"
            paddingTop="4"
          >
            <Heading mt={4} size="sm" alignSelf={'center'}>
              {fullName}
            </Heading>

            <Flex
              flexDir={'column'}
              alignItems="center"
              justifyContent="space-between"
              h="130"
            >
              {primaryOrg && (
                <Text variant="caption" fontSize={10}>
                  {primaryOrg.name || ''}
                </Text>
              )}
              {data.serviceTopic && (
                <Tag
                  fontSize={10}
                  fontWeight="bold"
                  color="primary"
                  mr={1}
                  p={2}
                >
                  {truncateString(data.serviceTopic)}
                </Tag>
              )}
              <Text color="primary" fontWeight="bold" fontSize="xs">
                {data.boostGroup && `Boost ${data.boostGroup.city}`}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Card>
    </Link>
  )
}

export default ProfileCard
