import { gql } from '@apollo/client'
import { objectToGQLSyntax } from '../../utils'

export const getLeadsQuery = () => gql`
query {
  leads {
      ${leadQueryProperties}
   }
  }
`
export const GetOwnLeadsQuery = (args: any) => gql`
query {
  leads(${objectToGQLSyntax(args)}) {
      ${leadQueryProperties}
   }
  }
  `
export const leadQueryProperties = `
    _id
    title
    findersFee
    sellingCoverage
    probability
    closingDate
    customer
    customerContact
    customerDescription
    description
    status
`
