import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input as ChakraInput,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement,
} from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props extends InputProps {
  Left?: any
  Right?: any
}

const Input: React.FC<Props> = ({ Left, Right, ...props }) => (
  <InputGroup>
    {Left && <InputLeftElement py={6}>{Left}</InputLeftElement>}
    <ChakraInput {...props} />
    {Right && <InputRightElement py={6}>{Right}</InputRightElement>}
  </InputGroup>
)

interface FormInputProps extends Props {
  label?: string
  error?: string
  helperText?: string
}

export const FormInput: React.FC<FormInputProps> = ({
  label,
  helperText,
  error,
  ...inputProps
}) => {
  const { t } = useTranslation()

  return (
    <FormControl isInvalid={!!error}>
      {label && <FormLabel htmlFor={inputProps.name}>{label}</FormLabel>}
      <Input {...inputProps} />
      {error && <FormErrorMessage>{t(error)}</FormErrorMessage>}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}

export default Input
