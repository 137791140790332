import React from 'react'
import { Box, Heading } from '@chakra-ui/react'
import { MemberCompanies } from '../../../types/member'

interface Props {
  data: MemberCompanies
}

const CompanyHighlight: React.FC<Props> = ({ data }) => (
  <Box my={2}>
    <Heading size="xs"> {data.name}</Heading>
  </Box>
)

export default CompanyHighlight
