import { Flex, IconButton, InputGroup } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { FormInput } from '../../basic/Input'

const MultiInput: React.FC<any> = ({
  children,
  error,
  placeholder,
  onSubmit,
}) => {
  const [value, setValue] = useState('')

  return (
    <Flex>
      <InputGroup>
        <FormInput
          error={error}
          onChange={(event) => setValue(event.target.value)}
          value={value}
          placeholder={placeholder}
        />
        {children}
      </InputGroup>
      <IconButton
        aria-label="add email"
        margin={0}
        height={51}
        marginLeft={1.5}
        onClick={() => onSubmit(value)}
      >
        <FontAwesomeIcon icon="plus" />
      </IconButton>
    </Flex>
  )
}

export default MultiInput
