export const removeTypesFromObject = (object: any, target: string): any => {
  if (!object) {
    return object
  } if (Array.isArray(object)) {
    return object.map((v) => removeTypesFromObject(v, target))
  } if (typeof object === 'object') {
    const newObj: any = {}
    Object.entries(object).forEach(([key, v]) => {
      if (key !== target) {
        newObj[key] = removeTypesFromObject(v, target)
      }
    })
    return newObj
  }
  return object
}
