import { Box, Heading } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'
import { defaultPadding } from '../../theme'

interface Props {
  title?: string
  linkTo?: string
}

const PageSection: React.FC<Props> = ({ title, children, linkTo }) => (
  <Box my={defaultPadding}>
    {linkTo ? (
      <Link to={linkTo}>
        <Heading size="sm" mb={defaultPadding}>
          {title}
        </Heading>
      </Link>
    ) : (
      <Heading size="sm" mb={defaultPadding}>
        {title}
      </Heading>
    )}
    {children}
  </Box>
)

export default PageSection
