/* eslint-disable @typescript-eslint/naming-convention */
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper'
import { Meeting } from '../../types/meeting'
import PageSection from '../../components/Layout/PageSection'
import MeetingCard from '../../components/MeetingCard'
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/pagination'
import { getMeetingsQuery } from '../Meetings/query'

const currentDate = new Date().toISOString()

const Meetings = () => {
  const { data } = useQuery<{ meetings: Meeting[] }>(
    getMeetingsQuery({
      query: { from_gt: currentDate },
      sortBy: 'FROM_ASC',
      limit: 10,
    }),
  )
  const { t } = useTranslation()

  const meetings = data?.meetings || []
  return (
    <PageSection title={t('meetings')} linkTo={'/meetings'}>
      <Swiper
        slidesPerView={1}
        breakpoints={{
          768: {
            slidesPerView: 2,
          },
          1200: {
            slidesPerView: 3,
          },
        }}
        spaceBetween={8}
        modules={[Pagination]}
        pagination={{
          clickable: true,
        }}
      >
        {meetings?.map((meeting: Meeting) => (
          <SwiperSlide key={meeting?.from.toString()}>
            <MeetingCard
              data={meeting}
              variant={
                meeting?.meetingType === 'group' ? 'secondary' : 'primary'
              }
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </PageSection>
  )
}

export default Meetings
