import { Box, Flex, Text } from '@chakra-ui/react'
import { IconName } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const IconText: React.FC<{ icon?: IconName; color?: string }> = ({
  icon,
  children,
  color,
}) => (
  <Flex alignItems="center" color={color}>
    {icon ? <FontAwesomeIcon icon={icon} /> : <Box width={4} />}
    <Text fontWeight="bold" marginLeft={4}>
      {children}
    </Text>
  </Flex>
)

export default IconText
