import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome'
import { primaryColor } from '../theme/styles'

const FAIcon: React.FC<FontAwesomeIconProps> = (props) => (
  <FontAwesomeIcon
    {...props}
    style={{
      color: props.color || primaryColor,
    }}
  />
)

export default FAIcon
