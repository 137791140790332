import { Flex, Heading } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Meeting } from '../../../types/meeting'

interface Props {
  data: Meeting
  variant: string
}

const MeetingHeading: React.FC<Props> = ({ data, variant }) => {
  const { t } = useTranslation()
  const groupType =
    data.meetingType === 'group' ? t('groupMeeting') : t('teamMeeting')
  const BoostGroup = `Boost ${data.group.name}`

  return (
    <Flex flexDir="column" ml={2} mb={9}>
      <Heading mt={2} size="md" color={variant}>
        {groupType}
      </Heading>
      <Heading mt={2} as="h1" color={variant}>
        {BoostGroup}
      </Heading>
    </Flex>
  )
}

export default MeetingHeading
