import { Box } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { FormikInput } from '../../Inputs'
import FormikMultiInput from '../../Inputs/FormikMultiInput'

const ProfileServiceProductsArrayForm: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Box>
      <FormikMultiInput
        createObject={{
          description: '',
          headline: '',
        }}
        buttonText={t('addServiceProducts')}
        name="serviceProducts"
      >
        {({ index }: any) => (
          <>
            <FormikInput
              name={`serviceProducts.${index}.headline`}
              placeholder={t('headlinePlaceholder')}
            />
            <FormikInput
              name={`serviceProducts.${index}.description`}
              placeholder={t('descriptionPlaceholder')}
            />
          </>
        )}
      </FormikMultiInput>
    </Box>
  )
}

export default ProfileServiceProductsArrayForm
