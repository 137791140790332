import { EditIcon } from '@chakra-ui/icons'
import { Box, Flex, Heading, IconButton, Spacer } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import Card from '../../components/basic/Card'
import { ScrollTopButton } from '../../components/Buttons'
import Layout from '../../components/Layout'
import { useAuth } from '../../hooks'
import { useInternalQuery } from '../../hooks/useQuery'
import { Member, MemberCompanies } from '../../types/member'
import { pickFromArrByCondition } from '../../utils'
import CaseHighlight from './components/CaseHighlight'
import CompanyHighlight from './components/CompanyHighlight'
import MeetingOptionCard from './components/MeetingOption'
import PrimaryCompany from './components/PrimaryCompany'
import ProfileHeading from './components/ProfileHeading'
import ServiceHighlight from './components/ServiceHighlight'
import ContactDetails from './ContactDetails'
import MembershipDetailsCard from './MembershipDetailsCard'
import ProfileNotFoundPage from './NotFound'
import { getProfileQuery } from './query'
import { IMAGE_ENV, BLOB_STORAGE_ENDPOINT } from '../../constant'

const ProfileView: React.FC = () => {
  const { t } = useTranslation()
  const { profile } = useAuth()
  const { profileIdentifier } = useParams()
  const [bgImageBase, setBgImageBase] = useState<string | undefined>()
  const { data, loading, error } = useInternalQuery<Member>(
    getProfileQuery({
      query: {
        uniqueURLid: profileIdentifier,
      },
    }),
    'member',
  )
  const [primaryCompany, companies] = pickFromArrByCondition<MemberCompanies>(
    data?.companies,
    (company) => company.primary,
  )
  const isOwnProfile =
    data && profile?.userId && profile?.userId === data?.userId

  useEffect(() => {
    fetch(
      `${BLOB_STORAGE_ENDPOINT}/${IMAGE_ENV}/bg_${
        data ? data.userId : null
      }?time=${Date.now()}`,
    )
      .then((picData) => {
        if (picData.status === 200) {
          return picData.text()
        }
        return ''
      })
      .then((pic) => {
        setBgImageBase(pic)
      })
  }, [data])

  if (!loading && !data) {
    return <ProfileNotFoundPage />
  }

  return (
    <Layout
      loading={loading}
      backButton
      error={error}
      headerBackground={bgImageBase}
      image
    >
      {data && (
        <Flex width="100%" flexDir="column" position="relative">
          {isOwnProfile && (
            <Link to="/profile/edit">
              <IconButton
                aria-label="edit profile"
                variant="none"
                position="absolute"
                right={0}
              >
                <EditIcon />
              </IconButton>
            </Link>
          )}
          <ProfileHeading data={data} />

          <Box marginTop={10} margin="0 auto" maxWidth={768}>
            <ContactDetails profile={data} />
            <Spacer my={8} />
            <Box>
              <Heading size="md" my={8}>
                {data.phrase}
              </Heading>
              {data.aboutMe}
            </Box>
            <Spacer my={8} />
            {data.boostGroup && (
              <Card variant="gray">
                <Flex alignItems="center">
                  <FontAwesomeIcon icon="users" />
                  <Heading marginLeft={5} size="md">
                    Boost {data.boostGroup.city}
                  </Heading>
                </Flex>
              </Card>
            )}
            {data.bookMeetingURL && (
              <MeetingOptionCard bookMeetingURL={data.bookMeetingURL} />
            )}
            <Spacer my={8} />
            <Heading size="md"> {t('representedOrganizations')} </Heading>
            {primaryCompany && (
              <PrimaryCompany
                data={primaryCompany}
                description={data.detailedServicesPitch}
              />
            )}
            {companies?.map((company) => (
              <CompanyHighlight data={company} key={company.name} />
            ))}
            <Spacer my={12} />
            <Heading size="md">{t('services')}</Heading>
            {data.serviceProducts?.map((service) => (
              <ServiceHighlight data={service} key={service.headline} />
            ))}
            <Spacer my={8} />
            <Heading size="md">{t('cases')}</Heading>
            {data.cases?.map((memberCase) => (
              <CaseHighlight data={memberCase} key={memberCase.headline} />
            ))}
            {isOwnProfile && data.membership ? (
              <MembershipDetailsCard membershipData={data.membership} />
            ) : null}

            <ScrollTopButton />
          </Box>
        </Flex>
      )}
    </Layout>
  )
}

export default ProfileView
