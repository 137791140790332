import { gql, useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { Team } from '../types/team'

const getTeamsQuery = () => gql`
  query {
    teams {
      _id
      name
    }
  }
`

const useTeams = () => {
  const [boostTeams, setBoostTeams] = useState<{ teams: Team[] }>()
  const { data } = useQuery(getTeamsQuery())

  useEffect(() => {
    setBoostTeams(data)
  }, [data])

  return { boostTeams }
}

export default useTeams
