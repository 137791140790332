import React from 'react'
import { Lead } from '../../../../types/lead'
import LeadInfoForm from './LeadInfo'

interface Props {
  initialValues?: Partial<Lead>
  onSubmit: (values: Partial<Lead>, actions: any, leadReceiver: string) => void
  receiver?: boolean
}

const defaultInitialValues = {
  receiver: '',
  title: '',
  team: {
    name: '',
  },
  customer: '',
  customerContact: '',
  customerDescription: '',
  probability: 0,
}

export const LeadForm: React.FC<Props> = ({
  onSubmit,
  receiver = false,
  initialValues = defaultInitialValues,
}) => (
  <LeadInfoForm
    initialValues={initialValues}
    onSubmit={onSubmit}
    receiverFields={receiver}
  />
)

export default LeadForm
