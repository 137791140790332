import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import CenteredLayout from '../../components/Layout/CenteredLayout'
import { RegisterDTO } from '../../types/user'
import RegisterForm from './RegisterForm'
import { useAuth } from '../../hooks'

const RegisterPage: React.FC = () => {
  const { t } = useTranslation()
  const { user, register } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (user) {
      navigate('/')
    }
  }, [user])

  const handleSubmit = async (values: RegisterDTO) => {
    // this is just to convert email to lowercase
    const validatedValues = {
      email: values.email.toLowerCase(),
      password: values.password,
      repeatedPassword: values.repeatedPassword,
    }
    try {
      await register(validatedValues)
    } catch (e) {
      // eslint-disable-next-line
      console.log(e)
    }
  }

  return (
    <CenteredLayout title={t('registerPage.register')} hideLogo={false}>
      <RegisterForm onSubmit={handleSubmit} />
    </CenteredLayout>
  )
}

export default RegisterPage
