import { Image, ImageProps } from '@chakra-ui/react'

interface Props extends ImageProps {
  variant?: 'white'
}

const Logo: React.FC<Props> = ({ variant, ...props }) => (
  <Image
    width={100}
    src={variant ? '/images/logo-white.png' : '/images/logo.png'}
    {...props}
  />
)

export default Logo
