import { Heading, Text } from '@chakra-ui/react'
import React from 'react'
import Card from '../../../components/basic/Card'
import { MemberCases } from '../../../types/member'

type Props = {
  data: MemberCases
}

const CaseHighlight: React.FC<Props> = ({ data }) => (
  <Card my={2}>
    <Heading size="sm"> {data.headline}</Heading>
    <Text>{data.body}</Text>
  </Card>
)

export default CaseHighlight
