import { IsEmail, IsNotEmpty, MinLength } from 'class-validator'
import { Match } from './customValidator'

export class LoginRequest {
  @IsNotEmpty()
  readonly email!: string

  @IsNotEmpty({ message: 'requiredField' })
  readonly password!: string
}

export class RegisterRequest {
  @IsNotEmpty({ message: 'Email is required' })
  @IsEmail()
  readonly email!: string

  @IsNotEmpty({ message: 'Password is required' })
  @MinLength(6, { message: 'Your password must be at least 6 characters' })
  readonly password!: string

  @Match('password')
  @IsNotEmpty({ message: 'Password is required' })
  readonly repeatedPassword!: string
}

export class RefreshRequest {
  @IsNotEmpty({ message: 'The refresh token is required' })
  readonly refreshToken!: string
}
