const Button = {
  baseStyle: {
    marginTop: 'default',
  },
  defaultProps: {
    colorScheme: 'primary',
    variant: 'filled',
  },
  variants: {
    none: {
      color: 'primary',
      margin: 0,
    },
    filled: {
      backgroundColor: 'primary',
      color: 'white',
    },
    outline: {
      border: '1px solid',
      borderColor: 'gray',
      color: 'primary',
    },
  },
}

export default Button
