import { gql } from '@apollo/client'
import { objectToGQLSyntax } from '../../utils'

export const getMeetingsQuery = (args: any) => gql`
query {
  meetings (${objectToGQLSyntax(args)}) {
      ${meetingQueryProperties}
   }
  }
`

export const getMeetingQuery = (args: any) => gql`
query {
  meeting (${objectToGQLSyntax(args)}) {
      ${meetingQueryProperties}
   }
  }
`
export const getMeetingsSpecialQuery = (args: any) => gql`
query($profileId: 'ObjectId') {
  meetings (query: {
        group: { members_in: { _id: '$profileId' } }
      },
      ${objectToGQLSyntax(args)}) {
      ${meetingQueryProperties}
   }
  }
`

export const meetingQueryProperties = `
    _id
    uniqueURLid
    description
    meetingType
    group {
        _id
        city
        name
        members {
            _id
        }
    }
    from
    to
    location
`
