import { useQuery } from '@apollo/client'
import { Flex } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import PageSection from '../../components/Layout/PageSection'
import TrainingCard from '../../components/TrainingCard'
import { Training } from '../../types/training'
import { getTrainingsQuery } from '../Training/query'

const currentDate = new Date().toISOString()

const Trainings = () => {
  const { t } = useTranslation()
  const { data } = useQuery<{ trainings: Training[] }>(
    /* eslint-disable */
    getTrainingsQuery({
      query: { from_gt: currentDate },
      limit: 1,
      sortBy: 'FROM_ASC',
    }),
  )
  /* eslint-enable */

  return (
    <PageSection title={t('trainings')}>
      <Flex flexWrap="wrap" gridGap={2}>
        {data?.trainings.map((training: Training) => (
          <TrainingCard data={training} key={training.title} />
        ))}
      </Flex>
    </PageSection>
  )
}

export default Trainings
