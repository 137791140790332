import { gql } from '@apollo/client'
import { objectToGQLSyntax } from '../../utils'

export const getLeadQuery = (args: any) => gql`
query {
  lead (${objectToGQLSyntax(args)}){
      ${leadQueryProperties}
   }
  }
`

export const getReceiverLeadQuery = (args: any) => gql`
query {
  lead (${objectToGQLSyntax(args)}){
      ${leadQueryProperties}
      ${leadReceiverQueryProperties}
   }
  }
`

export const leadReceiverQueryProperties = `
    team {
        _id
        name
    }
`

export const leadQueryProperties = `
    _id
    title
    findersFee
    sellingCoverage
    probability
    closingDate
    customer
    customerContact
    customerDescription
    cancelDescription
    description
    status
`

export const receiverLeadQueryProperties = `
    findersFee
    description
    probability
`
