import { useQuery } from '@apollo/client'
import { Center, SimpleGrid, useBreakpointValue } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { Meeting } from '../../types/meeting'
import PageSection from '../../components/Layout/PageSection'
import MeetingCard from '../../components/MeetingCard'
import Layout from '../../components/Layout'
import { getMeetingsQuery } from './query'

const currentDate = new Date().toISOString()

const MeetingsListView = () => {
  const { data, loading, error } = useQuery<{ meetings: Meeting[] }>(
    /* eslint-disable */
    getMeetingsQuery({
      query: { from_gt: currentDate },
      sortBy: 'FROM_ASC',
      limit: 10,
    }),
  )
  /* eslint-enable */

  const meetings = data?.meetings || []
  const { t } = useTranslation()
  const variant = useBreakpointValue({ base: true, md: false })

  return (
    <Center>
      <Layout loading={loading} error={error} backButton>
        <PageSection title={t('meetings')}>
          <SimpleGrid columns={[1, null, 3]} h="100%" spacing={2}>
            {meetings?.map((meeting: Meeting, index: number) => (
              <MeetingCard
                key={meeting.from.toString()}
                variant={
                  meeting?.meetingType === 'group' ? 'secondary' : 'primary'
                }
                data={meeting}
                small={index !== 0 && variant}
              />
            ))}
          </SimpleGrid>
        </PageSection>
      </Layout>
    </Center>
  )
}

export default MeetingsListView
