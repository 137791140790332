/* eslint-disable @typescript-eslint/naming-convention */
export const primaryColor = '#203262'
export const secondaryColor = '#D67C00'
export const green = '#1C8169'
export const red = '#C2433B'
export const yellow = '#D67C00'
export const grey = 'rgba(32, 50, 98, 0.2)'
export const avatarColors = ['#D67C00', '#D6A700', '#C2433B', '#1C8169']

const styles = {
  global: {
    fontFamily: 'Arial',
    body: {
      margin: 0,
    },
  },
  space: {
    default: '2em',
  },
  colors: {
    gray: 'rgba(32, 50, 98, 0.1)',
    bg: '#ececec',
    bgScheme: {
      500: '#ececec',
    },
    primary: primaryColor,
    secondary: '#D67C00',
    secondaryScheme: {
      500: '#D67C00',
    },
    primaryScheme: {
      error: primaryColor,
    },
  },
}

export default styles
