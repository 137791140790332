import { SelectProps } from '@chakra-ui/react'
import { Select } from 'chakra-react-select'
import { useField, useFormikContext } from 'formik'
import { FC, useState } from 'react'
import { BaseProps, FormControl } from '../form-control'

export type SelectControlProps = BaseProps & {
  selectProps?: SelectProps
  placeholderColor: string
  options?: any
}

export const SelectControl: FC<SelectControlProps> = (
  props: SelectControlProps,
) => {
  const { name, label, placeholderColor, options, placeholder, ...rest } = props
  const [field] = useField(name)
  const [value, setValue] = useState<string | null>(null)
  const { setFieldValue } = useFormikContext()

  const onChangeSelect = (e: any) => {
    setFieldValue(name, e)
    setValue(e)
  }

  return (
    <FormControl name={name} label={label} {...rest}>
      <Select
        {...field}
        id={name}
        value={value}
        options={options}
        onChange={(e) => onChangeSelect(e)}
        placeholder={placeholder}
        placeholderColor="#808080"
        chakraStyles={{
          indicatorSeparator: () => ({
            display: 'none',
          }),
          placeholder: () => ({
            color: placeholderColor || '#808080',
            fontWeight: placeholderColor ? 'bold' : 'normal',
          }),
        }}
      />
    </FormControl>
  )
}

export default SelectControl
