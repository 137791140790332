import * as Realm from 'realm-web'
import { ChakraProvider } from '@chakra-ui/react'
import { Suspense } from 'react'
// @ts-ignore
import { Toaster } from 'react-hot-toast'
import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from '@apollo/client'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faChevronLeft,
  faCheck,
  faUsers,
  faClock,
  faLocationDot,
  faSearch,
  faEnvelope,
  faPhone,
  faPlus,
  faVideo,
  faUserGroup,
  faDoorOpen,
  faCalendarCheck,
  faHome,
  faBriefcase,
  faChevronRight,
  faPerson,
  faFilter,
  faSliders,
  faClose,
  faCircleExclamation,
  faPercentage,
  faEuroSign,
} from '@fortawesome/free-solid-svg-icons'
import {
  faTwitter,
  faLinkedin,
  faWhatsapp,
  faFacebook,
} from '@fortawesome/free-brands-svg-icons'

// @ts-ignore
import { registerLocale } from 'react-datepicker'
import fi from 'date-fns/locale/fi';
import Routes from './Routes'
import theme from './theme'
import Loader from './components/Loader'
import { ENVIRONMENT_ID } from './constant'
import { AuthProvider } from './lib/auth'
import { TagProvider } from './lib/tag'

registerLocale('fi', fi)

library.add(
  faPlus,
  faUsers,
  faVideo,
  faCheck,
  faLocationDot,
  faClock,
  faChevronLeft,
  faCalendarCheck,
  faSearch,
  faEnvelope,
  faPhone,
  faTwitter,
  faLinkedin,
  faWhatsapp,
  faFacebook,
  faUserGroup,
  faDoorOpen,
  faHome,
  faBriefcase,
  faChevronRight,
  faPerson,
  faFilter,
  faSliders,
  faClose,
  faCircleExclamation,
  faPercentage,
  faEuroSign,
)

/* eslint-disable */
const realmApp: Realm.App = new Realm.App({ id: ENVIRONMENT_ID })
/* eslint-enable */

const getValidAccessToken = async () => {
  const app = realmApp
  if (app.currentUser) {
    await app.currentUser.refreshCustomData()
  }

  return app.currentUser?.accessToken
}

const graphqlUri = `https://eu-central-1.aws.realm.mongodb.com/api/client/v2.0/app/${ENVIRONMENT_ID}/graphql` // eslint-disable-line
const client = new ApolloClient({
  link: new HttpLink({
    uri: graphqlUri,
    fetch: async (uri, options = {}) => {
      const accessToken = await getValidAccessToken()
      return fetch(uri, {
        ...options,
        headers: {
          ...options.headers,
          Authorization: `Bearer ${accessToken}`,
        },
      })
    },
  }),
  cache: new InMemoryCache(),
})

const App = () => (
  <Suspense fallback={<Loader />}>
    <ApolloProvider client={client}>
      <Toaster />
      <AuthProvider realmClient={realmApp}>
        <ChakraProvider theme={theme}>
          <TagProvider>
            <Routes />
          </TagProvider>
        </ChakraProvider>
      </AuthProvider>
    </ApolloProvider>
  </Suspense>
)

export default App
