import * as Realm from 'realm-web'
import React, { useState, useContext, createContext, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from '../hooks/useSnackbar'
import { LoginDTO, RegisterDTO } from '../types/user'
import { getOwnProfile } from '../pages/Profile/query'

export interface IAuthContext {
  isAuthenticated: boolean
  user?: any
  authLoading: boolean
  profile: any
  fullName?: string
  realmLogIn: (props: LoginDTO) => any
  register: (props: RegisterDTO) => any
  forgotPassword: (props: { email: string }) => Promise<void>
  updateUser: () => Promise<void>
  recoverPassword: (props: {
    password: string
    token: string
    tokenId: string
  }) => Promise<void>
  signOut: () => Promise<void>
}

export interface AppProviderProps {
  realmClient: Realm.App
}

// @ts-ignore
const AuthContext = createContext<IAuthContext>({})

export const AuthProvider: React.FC<AppProviderProps> = ({
  realmClient,
  children,
}) => {
  const auth = useProvideAuth(realmClient)
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}

export const useAuth = () => useContext(AuthContext)

export const useProvideAuth = (app: Realm.App) => {
  const [user, setUser] = useState(app.currentUser)
  const [getProfile, { data: userData, loading: profileLoading, refetch }] =
    useLazyQuery(getOwnProfile())
  const profile = userData?.member

  useEffect(() => {
    if (user && !profile) {
      getProfile({
        variables: {
          userId: user.id,
        },
      }).catch(() => {
        signOut()
      })
    }
  }, [user, profile])

  const [loading, setLoading] = useState(true)
  const { t } = useTranslation()
  const { sendSnackbarMessage } = useSnackbar()
  const sendErrorSnackBar = (message: string) => {
    sendSnackbarMessage(message, {
      messageType: 'error',
    })
  }
  const updateUser = async () => {
    refetch()
  }

  const realmLogIn = async ({ email, password }: LoginDTO) => {
    const credentials = Realm.Credentials.emailPassword(email, password)
    try {
      if (!loading) setLoading(true)
      const response: Realm.User = await app.logIn(credentials)
      localStorage.setItem('userId', response.id)
      setUser(response)
    } catch (err) {
      sendErrorSnackBar(t('loginFailed'))
      throw err
    } finally {
      setLoading(false)
    }
  }

  const register = async ({ email, password }: RegisterDTO) => {
    try {
      if (!loading) setLoading(true)
      await app.emailPasswordAuth.registerUser({
        email,
        password,
      })
      await realmLogIn({ email, password })
    } catch (err: any) {
      sendErrorSnackBar(t(`${err.errorCode}`, t('registerFailed')))
      throw err
    } finally {
      setLoading(false)
    }
  }

  const signOut = async () => {
    setLoading(true)
    localStorage.clear()
    app.removeUser(user!)
    sendSnackbarMessage(t('signOutSuccess'))
    setLoading(false)
    setUser(null)
  }

  const forgotPassword = async ({ email }: { email: string }) => {
    try {
      if (!loading) setLoading(true)
      await app.emailPasswordAuth.sendResetPasswordEmail(email)
      sendSnackbarMessage(t('emailSent'), { messageType: 'success' })
    } catch (err) {
      sendErrorSnackBar(t('emailSentFailed'))
      throw err
    } finally {
      setLoading(false)
    }
  }

  const recoverPassword = async (dataForRecovery: {
    password: string
    token: string
    tokenId: string
  }) => {
    try {
      if (!loading) setLoading(true)
      await app.emailPasswordAuth.resetPassword({ ...dataForRecovery })
      sendSnackbarMessage(t('passwordChanged'))
    } catch (err) {
      sendErrorSnackBar(t('passwordChangeFailed'))
      throw err
    } finally {
      setLoading(false)
    }
  }

  return {
    isAuthenticated: !!user,
    signOut,
    user,
    fullName: profile ? `${profile.firstName} ${profile.lastName}` : '',
    // eslint-disable-next-line no-underscore-dangle
    profile: { ...profile, id: profile?._id },
    realmLogIn,
    register,
    authLoading: profileLoading || !profile,
    forgotPassword,
    recoverPassword,
    updateUser,
  }
}
