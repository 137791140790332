import { Text, Link, Flex, Box } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import Card from '../../../components/basic/Card'

const MeetingOptionCard: React.FC<{ bookMeetingURL: string }> = ({
  bookMeetingURL,
}) => {
  const { t } = useTranslation()
  return (
    <Box my={4}>
      <Link
        color="white"
        href={bookMeetingURL}
        target="_blank"
        rel="noreferrer"
      >
        <Card variant="primary">
          <Flex mx={4} alignItems="center">
            <FontAwesomeIcon size="2x" icon="calendar-check" />
            <Box ml={8}>
              <Text fontWeight="bold"> {t('calendarForMeetings')} </Text>
              <Text fontWeight="light" variant="caption" color="white">
                {bookMeetingURL}
              </Text>
            </Box>
          </Flex>
        </Card>
      </Link>
    </Box>
  )
}

export default MeetingOptionCard
