import { Box, Flex, Heading, Text, useMediaQuery } from '@chakra-ui/react'
import { format } from 'date-fns'
import { fi } from 'date-fns/locale'
import React from 'react'
import { defaultPadding } from '../theme'
import { Training } from '../types/training'
import Card from './basic/Card'
import IconText from './IconText'
import Logo from './Logo'
import SpeakerItem from './SpeakerItem'

interface Props {
  data: Training
}

const TrainingCard: React.FC<Props> = ({ data }) => {
  const [isLargerThan1000] = useMediaQuery('(min-width: 1000px)')

  return (
    <Card variant="primary" maxWidth={['100%', '100%', '100%', '100%']}>
      <Flex
        color="white"
        p={defaultPadding}
        flexDir="column"
        alignItems="center"
        justifyContent="center"
      >
        <Logo variant="white" mb={6} />
        <Heading color="secondary"> {data.title} </Heading>
        <Text color="secondary">
          {String(
            format(new Date(data.from), 'EEEEEE dd.MM.yyyy', { locale: fi }),
          ).toUpperCase()}
        </Text>
        <Box>
          <IconText color="white" icon="clock">
            {`${format(new Date(data.from), 'HH.mm')}`} -{' '}
            {`${format(new Date(data.to), 'HH.mm')}`}
          </IconText>

          <IconText color="white" icon="location-dot">
            {data.location}
          </IconText>
        </Box>
        <Flex
          direction={isLargerThan1000 ? 'row' : 'column'}
          alignItems="flex-start"
          justifyContent="space-around"
          mt="6"
        >
          {data.speakers?.map((speaker) => (
            <SpeakerItem speaker={speaker} />
          ))}
        </Flex>
        <Text my={5}>{data.description}</Text>
      </Flex>
    </Card>
  )
}

export default TrainingCard
