import { Box, Input, InputGroup, InputProps } from '@chakra-ui/react'
import { useField } from 'formik'
import React, { FC, ForwardedRef } from 'react'
import { SelectOption } from '../../../../types/lead'
import DateControl from '../date-control'
import { CheckboxSingleControl } from '../form-checkbox'
import { BaseProps, FormControl } from '../form-control'
import SelectControl from '../select-control'
import TextareaControl from '../textarea-control'

export type InputControlProps = BaseProps & {
  inputProps?: InputProps
} & {
  type?: 'textarea' | 'boolean' | 'password' | 'text' | 'email' | 'select' | 'date'
  options?: SelectOption[]
  otherValues?: any
  placeholderColor?: string
  index?: number
}

export const FormikInput: FC<InputControlProps> = React.forwardRef(
  (props: InputControlProps, ref: ForwardedRef<HTMLInputElement>) => {
    const {
      name,
      children,
      placeholder,
      placeholderColor = '',
      label,
      type,
      options,
      inputProps,
      ...rest
    } = props
    const [field] = useField(name)
    if (type === 'boolean') {
      return <CheckboxSingleControl {...props}> </CheckboxSingleControl>
    }

    if (type === 'textarea') {
      return <TextareaControl {...props} />
    }

    if (type === 'date') {
      return (
        <DateControl {...props} />
      )
    }

    if (type === 'select') {
      return (
        <SelectControl {...props} options={options} placeholderColor={placeholderColor}>
          {children}
        </SelectControl>
      )
    }

    return (
      <FormControl marginY={4} name={name} label={label} {...rest}>
        <InputGroup>
          <Input
            {...field}
            value={field.value || ''}
            placeholder={placeholder}
            type={type || 'text'}
            id={name}
            {...inputProps}
            ref={ref}
          />
          <Box zIndex={0}>{children}</Box>
        </InputGroup>
      </FormControl>
    )
  },
)

export default FormikInput
