import React, { useContext, createContext } from 'react'
import ReactGA from 'react-ga4'
import { PRODUCTION } from '../constant'

export interface ITagContext {
  createEvent: (hitType: string, page: string) => void
}

// @ts-ignore
const TagContext = createContext<ITagContext>({})

export const TagProvider: React.FC = ({ children }) => {
  const auth = useProvideTag()
  return <TagContext.Provider value={auth}>{children}</TagContext.Provider>
}

export const useTag = () => useContext(TagContext)

export const useProvideTag = () => {
  if (PRODUCTION) {
    ReactGA.initialize('G-XRFYJTW195')
  }

  const createEvent = (hitType: string, page: string) => {
    ReactGA.set({
      page,
    })

    ReactGA.send('pageview')
  }
  return {
    createEvent,
  }
}
