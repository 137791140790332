import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import CenteredLayout from '../../components/Layout/CenteredLayout'
import LoginForm from './LoginForm'
import { useAuth } from '../../hooks'
import { LoginDTO } from '../../types/user'

const LogInPage: React.FC = () => {
  const { t } = useTranslation()
  const { user, realmLogIn } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (user) {
      navigate('/')
    }
  }, [user])

  const handleSubmit = async (values: LoginDTO) => {
    try {
      // this is just to convert email to lowercase
      const validatedValues = {
        email: values.email.toLowerCase(),
        password: values.password,
      }
      await realmLogIn(validatedValues)
    } catch (e) {
      // eslint-disable-next-line
      console.log(e)
    }
  }

  return (
    <CenteredLayout
      title={t('logIn')}
      noBackButton
      showNav={false}
      hideLogo={false}
    >
      <LoginForm onSubmit={handleSubmit} />
    </CenteredLayout>
  )
}

export default LogInPage
