import React from 'react'
import { CreateMember } from '../../../../types/member'
import ProfileBasicInfoForm from './BasicInfo'

interface Props {
  initialValues?: CreateMember
  onSubmit: (values: CreateMember, actions: any) => void
}

const defaultInitialValues = {
  userId: '',
  firstName: '',
  lastName: '',
  role: '',
  phrase: '',
  detailedServicesPitch: '',
  services: '',
  phoneNumber: '',
  aboutMe: '',
  linkedin: '',
  twitter: '',
  facebook: '',
  locations: [],
  bookMeeting: [],
  emails: [],
  companies: [],
  serviceProducts: [],
  cases: [],
}

export const MemberForm: React.FC<Props> = ({
  onSubmit,
  initialValues = defaultInitialValues,
}) => (
  <ProfileBasicInfoForm
    initialValues={{ ...initialValues }}
    onSubmit={onSubmit}
  />
)

export default MemberForm
