import { useTranslation } from 'react-i18next'
import { Formik, Form, useFormikContext } from 'formik'
import { ConnectedFocusError } from 'focus-formik-error'
import { Box } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FloatingBar from '../../../basic/FloatingBar'
import InputRightElement from '../../Inputs/InputRightThingy'
import { CreateMember } from '../../../../types/member'
import { FormikInput, SubmitButton } from '../../Inputs'
import ProfileLocationArrayForm from './LocationArrayForm'
import ProfileEmailArrayForm from './EmailArrayForm'
import ProfileCompaniesArrayForm from './CompaniesArrayForm'
import ProfileServiceProductsArrayForm from './ServiceProductsArrayForm'
import ProfileCasesArrayForm from './CasesArrayForm'
import { formikErrorLocalisations } from '../../../../lib/i18n'
import FormHeader from '../formHeader'
import { formValidationSchema } from './validationSchema'

formikErrorLocalisations()

interface Props {
  initialValues: CreateMember
  onSubmit: (values: CreateMember, actions: any) => void
}

const ProfileBasicInfoForm: React.FC<Props> = ({ initialValues, onSubmit }) => {
  const { t } = useTranslation()
  return (
    <Formik
      onSubmit={(values, actions) => {
        // @ts-ignore
        onSubmit(formValidationSchema.noUnknown().cast(values), actions)
      }}
      initialValues={initialValues}
      validationSchema={formValidationSchema}
      validateOnChange={false}
    >
      <Form>
        <ConnectedFocusError />
        <Box>
          <FormHeader title={t('basicDetails')}>
            <FormikInput
              name={`firstName`}
              placeholder={t('firstNamePlaceholder')}
              inputProps={{
                isDisabled: true,
              }}
            />
            <FormikInput
              name={`lastName`}
              placeholder={t('lastNamePlaceholder')}
              inputProps={{
                isDisabled: true,
              }}
            />

            <FormikInput name={`jobTitle`} placeholder={t('rolePlaceholder')} />

            <FormikInput name={`phrase`} placeholder={t('phrasePlaceholder')} />
            <FormikInput
              name={`aboutMe`}
              placeholder={t('aboutMePlaceholder')}
              type="textarea"
            />
          </FormHeader>
          <FormHeader title={t('contactDetails')}>
            <FormikInput
              name={`phoneNumber`}
              placeholder={t('phoneNumberPlaceholder')}
            />

            <ProfileEmailArrayForm name="emails" />
            <FormikInput
              name={`linkedin`}
              placeholder={t('linkedinPlaceholder')}
            >
              <InputRightElement>
                <FontAwesomeIcon icon={['fab', 'linkedin']} />
              </InputRightElement>
            </FormikInput>

            <FormikInput
              name={`facebook`}
              placeholder={t('facebookPlaceholder')}
            >
              <InputRightElement>
                <FontAwesomeIcon icon={['fab', 'facebook']} />
              </InputRightElement>
            </FormikInput>

            <FormikInput name={`twitter`} placeholder={t('twitterPlaceholder')}>
              <InputRightElement>
                <FontAwesomeIcon icon={['fab', 'twitter']} />
              </InputRightElement>
            </FormikInput>
          </FormHeader>
          <FormHeader title={t('aboutProvidedServices')}>
            <FormikInput
              name={`serviceTopic`}
              placeholder={t('seviceTopicPlaceholder')}
            />
            <FormikInput
              name={`detailedServicesPitch`}
              placeholder={t('detailedServicesPitchPlaceholder')}
              type="textarea"
            />
          </FormHeader>
          <FormHeader title={t('meetingCalendar')}>
            <FormikInput
              name={`bookMeetingURL`}
              placeholder={t('meetingCalendarPlaceholder')}
            />
          </FormHeader>
          <FormHeader title={t('companies')}>
            <ProfileCompaniesArrayForm />
          </FormHeader>
          <FormHeader title={t('address')}>
            <ProfileLocationArrayForm />
          </FormHeader>
          <FormHeader title={t('services')}>
            <ProfileServiceProductsArrayForm />
          </FormHeader>
          <FormHeader title={t('cases')}>
            <ProfileCasesArrayForm />
          </FormHeader>
        </Box>
        <FormBar />
      </Form>
    </Formik>
  )
}

const FormBar = () => {
  const { t } = useTranslation()
  const { dirty } = useFormikContext()

  return (
    <FloatingBar>
      <SubmitButton
        margin={0}
        disabled={!dirty}
        width={'80%'}
        bg="#3CBA57"
        type="submit"
      >
        {t('submit')}
      </SubmitButton>
    </FloatingBar>
  )
}

export default ProfileBasicInfoForm
