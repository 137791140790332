import { useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import { useSearchParams, useNavigate } from 'react-router-dom'
import createValidator from 'class-validator-formik'
import { useTranslation } from 'react-i18next'
import { SubmitButton } from '../../components/formik/Inputs'
import { NewPassword } from '../../validation/forgotPassword'
import CenteredLayout from '../../components/Layout/CenteredLayout'
import { useAuth } from '../../hooks'
import { FormikPasswordInput } from '../../components/formik/Inputs/PasswordInput'

interface RecorveryToken {
  token: string
  tokenId: string
}

const RecoverPasswordPage: React.FC = () => {
  const [searchParams] = useSearchParams()
  const [recoveryToken, setRecoveryToken] = useState<RecorveryToken | null>(
    null,
  )
  const { recoverPassword } = useAuth()
  const { t } = useTranslation()
  const navigate = useNavigate()

  useEffect(() => {
    setRecoveryToken({
      token: searchParams.get('token')!,
      tokenId: searchParams.get('tokenId')!,
    })
  }, [])

  const handleSubmit = async ({ password }: { password: string }) => {
    try {
      await recoverPassword({ password, ...recoveryToken! })
      navigate('/login')
    } catch (e) {
      // eslint-disable-next-line
      console.log(e)
    }
  }

  return (
    <CenteredLayout title={t('recoverPasswordPage')} noBackButton>
      <Formik
        initialValues={{ password: '' }}
        onSubmit={handleSubmit}
        validate={createValidator(NewPassword)}
      >
        <Form>
          <FormikPasswordInput name="password" label={t('password')} />
          <SubmitButton isFullWidth> {t('recoverPassword')} </SubmitButton>
        </Form>
      </Formik>
    </CenteredLayout>
  )
}

export default RecoverPasswordPage
