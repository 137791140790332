import { Flex } from '@chakra-ui/react'
import { Spinner } from '@chakra-ui/spinner'

const Loader: React.FC = () => (
  <Flex mt={5} justifyContent="center">
    <Spinner justifySelf="center" />
  </Flex>
)

export default Loader
