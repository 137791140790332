import { gql, useQuery } from '@apollo/client'
import { Box, Flex, Spinner } from '@chakra-ui/react'

import { MultiValue, Select } from 'chakra-react-select'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebounce } from 'use-debounce'
import Input from '../../components/basic/Input'
import FAIcon from '../../components/FAIcon'
import DefaultLayout from '../../components/Layout'
import MemberList from '../../components/MemberList'
import convertArrayToSelectOptions from '../../helpers/convertArrayToSelectOptions'
import useGroups from '../../hooks/useGroups'
import useTeams from '../../hooks/useTeams'
import { defaultPadding } from '../../theme'
import { memberQueryProperties } from '../Profile/query'

type FilterValue = {
  label: string
  value: string
}
const Boosters: React.FC = () => {
  const [value, setValue] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [filtersVisible, setFiltersVisible] = useState<boolean>(false)
  const [teams, setTeams] = useState<string[]>([])
  const [groups, setGroups] = useState<string[]>([])
  const [searchWord] = useDebounce(value, 1500)
  const { boostGroups } = useGroups()
  const { boostTeams } = useTeams()
  const { t } = useTranslation()
  const query = gql`
    query improvedSearch($input: SearchInputType!) {
      improvedSearch(input: $input) {
        ${memberQueryProperties}
      }
    }
  `
  const { data, refetch } = useQuery(query, {
    variables: {
      input: { searchWord: '', teams, groups },
    },
  })
  const results = data?.improvedSearch || []

  useEffect(() => {
    getResults()
  }, [searchWord, teams, groups])

  const getResults = async () => {
    if (refetch && value) {
      await refetch({
        input: { searchWord, teams, groups },
      })
      setLoading(false)
    }
    setLoading(false)
  }

  const onChangeSearch = (text: string) => {
    setLoading(true)
    setValue(text)
  }

  const onChangeFilter = (
    filters: MultiValue<FilterValue>,
    filterName: string,
  ) => {
    if (filterName === 'teams') {
      const teamNames = filters.map((f) => f.label)
      setTeams(teamNames)
    } else {
      const groupNames = filters.map((f) => f.label)
      setGroups(groupNames)
    }
  }

  const filterStyles = {
    control: (provided: any) => ({
      ...provided,
      padding: '5px',
      margin: '5px',
    }),
    container: (provided: any) => ({
      ...provided,
      flex: 1,
      padding: '5px',
    }),
    multiValue: (provided: any) => ({
      ...provided,
      backgroundColor: 'orange.400',
      color: '#ffffff',
    }),
    multiValueLabel: (provided: any) => ({
      ...provided,
      padding: '5px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
  }

  return (
    <DefaultLayout title={t('boosters')}>
      <Box>
        <Flex
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Input
            value={value}
            onChange={(event) => onChangeSearch(event.target.value)}
            Left={<FAIcon icon={'search'} />}
            placeholder={t('search by name or organization')}
            marginBottom={defaultPadding}
          />
          <Box marginBottom={defaultPadding} padding={defaultPadding}>
            {!filtersVisible ? (
              <FAIcon
                icon={'sliders'}
                color={'#203262'}
                onClick={() => setFiltersVisible(!filtersVisible)}
              />
            ) : (
              <FAIcon
                icon={'close'}
                color={'#203262'}
                onClick={() => setFiltersVisible(!filtersVisible)}
              />
            )}
          </Box>
        </Flex>
        {filtersVisible && (
          <Box>
            <Flex
              direction="row"
              alignItems="center"
              alignSelf="flex-start"
              flexWrap="wrap"
            >
              <Select
                placeholder="Kaikki tiimit"
                onChange={(e: MultiValue<FilterValue>) =>
                  onChangeFilter(e, 'teams')
                }
                chakraStyles={filterStyles}
                isMulti
                options={convertArrayToSelectOptions(
                  boostTeams ? boostTeams.teams : [],
                )}
              />
              {/* <Box padding={7} /> */}
            </Flex>
            <Flex
              direction="row"
              alignItems="center"
              alignSelf="flex-start"
              flexWrap="wrap"
            >
              <Select
                placeholder="Kaikki ryhmät"
                chakraStyles={filterStyles}
                onChange={(e: MultiValue<FilterValue>) =>
                  onChangeFilter(e, 'groups')
                }
                isMulti
                options={convertArrayToSelectOptions(
                  boostGroups ? boostGroups.groups : [],
                )}
              />
            </Flex>
          </Box>
        )}
        {loading ? (
          <Flex alignItems="center" justifyContent="center" direction="row">
            <Spinner />
          </Flex>
        ) : null}
        {results.length > 0 ? (
          <MemberList data={results} navigateToProfile />
        ) : null}
      </Box>
    </DefaultLayout>
  )
}
export default Boosters
