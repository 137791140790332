import { Button } from '@chakra-ui/react'

type Props = {
  color: string
  textColor: string
  text: string
  hasBorder?: boolean
  fontSize?: string[]
  onClick: () => void
}

const BasicButton: React.FC<Props> = ({
  color,
  textColor,
  text,
  hasBorder = false,
  fontSize = [],
  onClick,
  ...props
}) => (
  <Button
    backgroundColor={color}
    color={textColor}
    fontSize={fontSize}
    border={hasBorder ? '1px solid rgba(32, 50, 98, 0.1)' : 'hide'}
    w={['100%', '100%', '100%', '100%']}
    p="6"
    m="2"
    onClick={onClick}
    {...props}
  >
    {text}
  </Button>
)

export default BasicButton
