import { useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { useAuth } from '.'
import { GetOwnLeadsQuery } from '../pages/Leads/query'
import { Lead } from '../types/lead'

const useLeads = () => {
  const [leads, setLeads] = useState<Lead[]>()
  const { profile } = useAuth()
  const { data } = useQuery<{ leads: Lead[] }>(
    GetOwnLeadsQuery({
      query: { receiverId: profile.userId },
    }),
  )

  useEffect(() => {
    setLeads(data?.leads)
  }, [data])

  return { leads }
}

export default useLeads
