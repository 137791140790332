import { Text, Heading, Flex, Box } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import Card from '../../components/basic/Card'
import { MembershipDetails } from '../../types/member'
import Logo from '../../components/Logo'

interface Props {
  membershipData: MembershipDetails
}

const MembershipDetailsCard: React.FC<Props> = ({ membershipData }) => {
  const { t } = useTranslation()
  const { expiresAt } = membershipData

  return (
    <Card variant="primary" mt={8}>
      <Box padding={6}>
        <Flex justifyContent="space-between" alignItems="center">
          <Box>
            <Heading size="sm" color="white">
              {t('membership').toUpperCase()}
            </Heading>
            <Text color="white">
              {t('expiresAt')}{' '}
              {expiresAt && format(new Date(expiresAt), 'dd.MM.yyyy')}
            </Text>
          </Box>
          <Logo variant="white" />
        </Flex>
        <Text mt={6} textAlign="center" color="white">
          {t('membershipRenewalText')}
        </Text>
      </Box>
    </Card>
  )
}
export default MembershipDetailsCard
