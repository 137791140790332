import { useField, useFormikContext } from 'formik'
import DatePicker from 'react-datepicker'
import { FC } from 'react'
import { BaseProps, FormControl } from '../form-control'
import Input from '../../../basic/Input'
import 'react-datepicker/dist/react-datepicker.css'

export type DateControlProps = BaseProps & {
  dateProps?: any
}

export const DateControl: FC<any> = (props: any) => {
  const { name, label, ...rest } = props
  const [field] = useField(name)
  const { setFieldValue } = useFormikContext()

  return (
    <FormControl name={name} label={label} {...rest}>
      <DatePicker
        {...field}
        {...props}
        customInput={<Input />}
        dateFormat={'dd.MM.yyyy'}
        locale="fi"
        selected={(field.value && new Date(field.value)) || null}
        onChange={(val) => setFieldValue(field.name, val)}
      />
    </FormControl>
  )
}

export default DateControl
