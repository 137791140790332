import toast from 'react-hot-toast'

export interface SnackMessageProps {
  timeout?: number
  messageType?: 'error' | 'success'
}

export const useSnackbar = () => ({
  /* eslint-disable-next-line */
  sendSnackbarMessage: (message: string, options?: SnackMessageProps) =>
    options?.messageType
      ? toast[options.messageType](message, {
          duration: options.timeout || 3000,
          position: 'bottom-right',
          style: {
            borderRadius: '4px',
            backgroundColor: '#f44336',
            color: '#fff',
          },
        })
      : toast(message, {
          duration: options?.timeout || 3000,
          position: 'bottom-right',
          style: {
            borderRadius: '4px',
            backgroundColor: '#1C8169',
            color: '#fff',
          },
        }),
})
