import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { setLocale } from 'yup'

// If you are not able to use the t wrapper and want to get some translation working
// force the extraction here.
export const hackForDynamicTranslations = [i18n.t('validUrl')]

export const formikErrorLocalisations = () =>
  setLocale({
    mixed: {
      required: 'requiredField',
    },
    string: {
      url: 'validUrl',
    },
  })

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: 'fi',
    fallbackLng: 'fi',
    defaultNS: 'translation',
    fallbackNS: 'translation',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    debug: true,
  })

export default i18n
