import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Flex, Radio, Text } from '@chakra-ui/react'
import InputRightElement from '../../Inputs/InputRightThingy'
import { FormikInput, RadioGroupControl } from '../../Inputs'
import FAIcon from '../../../FAIcon'
import { Lead } from '../../../../types/lead'

interface Props {
  initialValues: Lead
}

const ReceiverLeadForm: React.FC<Props> = ({
  initialValues: { findersFee },
}: Props) => {
  const { t } = useTranslation()
  const [showDescription, setShowDescription] = useState(!findersFee || false)
  return (
    <>
      <FormikInput
        name={`status`}
        placeholder={'Auki'}
        type="select"
        style={{ marginTop: 15 }}
        placeholderColor={'#D67C00'}
        options={[
          { value: 'won', label: 'Voitettu' },
          { value: 'lost', label: 'Hävitty' },
        ]}
      />
      <FormikInput
        name={'closingDate'}
        type="date"
        placeholder={t('lead.closingDate')}
        label={t('lead.closingDate')}
        style={{ width: '100%', marginTop: 15 }}
      />
      <Flex direction={'row'} justify={'space-between'}>
        <FormikInput
          name={'probability'}
          w={'49%'}
          placeholder={'0'}
          label={t('lead.probability')}
        >
          <InputRightElement>
            <FAIcon icon="percentage" />
          </InputRightElement>
        </FormikInput>
        <FormikInput
          name={'sellingCoverage'}
          placeholder={'0'}
          w={'49%'}
          label={t('lead.sellingCoverage')}
        >
          <InputRightElement>
            <FAIcon icon="euro-sign" />
          </InputRightElement>
        </FormikInput>
      </Flex>
      <Text>Finders Fee</Text>
      <RadioGroupControl name="findersFee" defaultValue={`${!showDescription}`}>
        <Flex w={'60%'} direction={'row'} justify={'space-around'}>
          <Radio
            key={'true'}
            checked={showDescription}
            onChange={() => {
              setShowDescription(true)
            }}
            size={'lg'}
            colorScheme={'telegram'}
            defaultChecked
            value={'true'}
            style={{ border: '2px grey solid' }}
          >
            {t('lead.paying')}
          </Radio>
          <Radio
            key={'false'}
            size={'lg'}
            checked={showDescription}
            onChange={() => {
              setShowDescription(false)
            }}
            colorScheme={'telegram'}
            value="false"
            style={{ border: '2px grey solid' }}
          >
            {t('lead.notPaying')}
          </Radio>
        </Flex>
      </RadioGroupControl>
      {!showDescription && (
        <FormikInput
          name={'cancelDescription'}
          placeholder={'syy'}
          label="Hylkäämisen syy"
        />
      )}
    </>
  )
}

export default ReceiverLeadForm
