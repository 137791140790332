import { gql, useMutation } from '@apollo/client'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@chakra-ui/react'
import MemberForm from '../../components/formik/forms/profile/ProfileForm'
import Layout from '../../components/Layout'
import { removeTypesFromObject } from '../../helpers/objectManipulation'
import { useAuth } from '../../hooks'
import { CreateMember } from '../../types/member'
import ProfileHeading from './components/ProfileHeading'
import { useSnackbar } from '../../hooks/useSnackbar'
import { resizeImage } from '../../helpers/image'
import { updateMemberQueryProperties } from './query'
import { IMAGE_ENV, BLOB_STORAGE_ENDPOINT } from '../../constant'

const memberMutation = gql`
  mutation updateMember($set: MemberUpdateInput!, $query: MemberQueryInput) {
    updateOneMember(set: $set, query: $query) {
      ${updateMemberQueryProperties}
    }
  }
`

const ProfileEdit: React.FC = () => {
  const [updateUser] = useMutation(memberMutation)
  const [imageBase, setImage] = useState<string | undefined>()
  const [bgImageBase, setBgImageBase] = useState<string | undefined>()

  const { t } = useTranslation()
  const { sendSnackbarMessage } = useSnackbar()
  const { sendSnackbarMessage: sendErrorSnackBar } = useSnackbar()
  const { profile: data, user } = useAuth()
  const loading = !data

  const fetchImage = () => {
    fetch(
      `${BLOB_STORAGE_ENDPOINT}/${IMAGE_ENV}/bg_${
        data ? data.userId : null
      }?time=${Date.now()}`,
    )
      .then((picData: any): string => {
        if (picData.status === 200) {
          return picData.text()
        }
        return ''
      })
      .then((pic: any) => {
        setBgImageBase(pic)
      })
  }

  useEffect(() => {
    fetchImage()
  }, [data])

  const saveImage = async (
    img: string,
    imgLength: number,
    background: boolean,
  ) => {
    await user.callFunction('uploadProfileImage', {
      img,
      imgLength,
      background,
    })
  }

  const handleBgImageChange = async (event: any) => {
    const [file] = event.target.files
    const baseImage: string = (await resizeImage(file, 300, 1000)) as string
    setBgImageBase(baseImage)
    try {
      await saveImage(baseImage, file.size, true)
      fetchImage()
      sendSnackbarMessage(t('backgroundProfilePicturechanged'))
    } catch (e: any) {
      fetchImage()
      sendErrorSnackBar(t('backgroundProfilePicturechangedFailed'))
    }
  }

  const handleImageChange = async (event: any) => {
    const [file] = event.target.files
    const baseImage: string = (await resizeImage(file, 150, 150)) as string
    setImage(baseImage)
    try {
      await saveImage(baseImage, file.size, false)
      sendSnackbarMessage(t('profilePicturechanged'))
    } catch (e: any) {
      sendErrorSnackBar(t('profilePicturechangedFailed'))
    }
  }

  const handleSubmit = (values: CreateMember, actions: any) => {
    updateUser({
      variables: {
        set: {
          ...removeTypesFromObject(values, '__typename'),
          uniqueURLid: values.uniqueURLid,
        },
        query: { userId: data.userId },
      },
    })
    sendSnackbarMessage(t('profileUpdated'))
    actions.setSubmitting(false)
  }

  return (
    <Layout
      // title={t('editProfile')}
      backButton
      showImageUpload
      loading={loading}
      headerBackground={bgImageBase}
      handleImageChange={handleBgImageChange}
      image
    >
      <Box margin="0 auto" maxW="1024">
        <ProfileHeading
          data={data}
          imageBase={imageBase}
          showImageUpload
          handleImageChange={handleImageChange}
        />

        <MemberForm onSubmit={handleSubmit} initialValues={data} />
      </Box>
    </Layout>
  )
}

export default ProfileEdit
