import { Box, Heading } from '@chakra-ui/react'
import { defaultPadding } from '../../../theme'
import { MemberCompanies } from '../../../types/member'

interface Props {
  data: MemberCompanies
  description?: String
}

const PrimaryCompany: React.FC<Props> = ({ data, description }) => (
  <Box my={defaultPadding}>
    <Heading size="sm" mb={4}>
      {data.name}
    </Heading>
    {description}
  </Box>
)

export default PrimaryCompany
