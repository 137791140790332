import { Box } from '@chakra-ui/layout'
import { Tag, TagProps } from '@chakra-ui/react'
import { green, red, yellow, grey } from '../../theme/styles'

type Props = {
  text: string
}

const getColorSchemeByStatus = (text: string) => {
  switch (text) {
    case 'won':
      return green
    case 'lost':
      return red
    case 'open':
      return yellow
    case 'canceled':
      return grey
    case 'rejected':
      return red
    default:
      return grey
  }
}

const StatusBadge: React.FC<Props> = ({ text, children }) => {
  const outlineVariant = text === 'lost'
  const color = getColorSchemeByStatus(text)

  const getStyleForTagComponent = (): TagProps => {
    if (outlineVariant) {
      return {
        variant: 'outline',
        color,
        colorScheme: 'red',
      }
    }
    return { backgroundColor: color }
  }

  return (
    <Tag
      fontWeight="bold"
      {...getStyleForTagComponent()}
      fontSize={['sm', 'md', 'lg']}
      pr="4"
    >
      <Box
        w="2"
        h="2"
        mr="2"
        borderRadius="100"
        backgroundColor={outlineVariant ? color : '#ffffff'}
      />
      {children}
    </Tag>
  )
}

export default StatusBadge
