import { extendTheme, ThemeOverride } from '@chakra-ui/react'
import styles from './styles'
import Button from './components/button'

export const defaultPadding = 5

// TODO: Proper setup for the font stuff, chakra's options are bit annoying
const overrides: ThemeOverride = {
  ...styles,
  components: {
    Button,
    // TODO: internal tag component, this css stuff doesnt work properly for it...
    Tag: {
      defaultProps: {
        variant: 'solid',
        fontWeight: 'bold',
        colorScheme: 'bgScheme',
      },
    },
    Checkbox: {
      defaultProps: {
        colorScheme: 'primaryScheme',
      },
    },
    Link: {
      baseStyle: {
        color: 'primary',
        fontWeight: 'bold',
      },
    },
    Input: {
      baseStyle: {
        field: {
          paddingY: 6,
          backgroundColor: 'rgba(32, 50, 98, 0.05)',
          _hover: {
            backgroundColor: 'rgba(32, 50, 98, 0.10)',
          },
        },
      },
      defaultProps: {
        variant: 'filled',
      },
    },
    Heading: {
      defaultProps: {
        colorScheme: 'primary',
        variant: 'primary',
      },
      variants: {
        primary: {
          fontFamily: 'Arial, cantarell, sans-serif',
          color: 'primary',
        },
      },
    },
    Textarea: {
      baseStyle: {
        backgroundColor: 'rgba(32, 50, 98, 0.05)',
        _hover: {
          backgroundColor: 'rgba(32, 50, 98, 0.10)',
        },

        my: 2,
      },
      defaultProps: {
        variant: 'filled',
      },
    },
    Text: {
      baseStyle: {
        my: 2,
      },
      variants: {
        caption: {
          fontFamily: 'Arial, cantarell, sans-serif',

          marginTop: 2,
          marginBottom: 2,
          color: 'primary',
        },
      },
    },
    Tooltip: {
      baseStyle: {
        background: 'primary',
      },
    },
  },
}

export default extendTheme(overrides)
