import {
  FormControl as ChakraFormControl,
  FormControlProps,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
} from '@chakra-ui/react'
import { useField } from 'formik'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

export interface BaseProps extends FormControlProps {
  name: string
  label?: string
  helperText?: string
}

export const FormControl: FC<BaseProps> = (props: BaseProps) => {
  const { children, name, label, helperText, ...rest } = props
  const [, { error, touched }] = useField(name)
  const { t } = useTranslation()

  return (
    <ChakraFormControl isInvalid={!!error && touched} {...rest}>
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      {children}
      {error && <FormErrorMessage>{t(error)}</FormErrorMessage>}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </ChakraFormControl>
  )
}

export default FormControl
