import { Form, Formik } from 'formik'
import { Box } from '@chakra-ui/react'
import createValidator from 'class-validator-formik'
import { useTranslation } from 'react-i18next'
import { FormikInput, SubmitButton } from '../../components/formik/Inputs'
import { FormikPasswordInput } from '../../components/formik/Inputs/PasswordInput'
import RouterLink from '../../components/basic/RouterLink'
import { RegisterRequest } from '../../validation/auth'
import { AuthRegisterProps } from '../../types/auth'
import LinkButton from '../../components/Buttons/LinkButton'

const RegisterForm: React.FC<AuthRegisterProps> = ({ onSubmit }: any) => {
  const { t } = useTranslation()

  const initialValues = {
    email: '',
    password: '',
    repeatedPassword: '',
  }

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={createValidator(RegisterRequest)}
    >
      <Form>
        <FormikInput label={t('email')} name="email" />
        <FormikPasswordInput label={t('password')} name="password" />
        <FormikPasswordInput
          label={t('registerPage.repeatedPassword')}
          name="repeatedPassword"
        />
        <Box mt={5} mb={5}>
          <RouterLink to="/LogIn">
            {t('registerPage.alreadyAccount')}
          </RouterLink>
          <LinkButton
            external
            to="https://boostfinland.fi/jaseneksi/"
            variant="outline"
            isFullWidth
          >
            {t('applyForMembership')}
          </LinkButton>
        </Box>
        <SubmitButton> {t('registerPage.register')} </SubmitButton>
      </Form>
    </Formik>
  )
}

export default RegisterForm
