import { Text } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import FAIcon from './FAIcon'

type Props = {
  text: string
  color: string
  icon: IconProp
  iconColor?: string
  to: string
  isLargerThan1000?: boolean
}

const NavItem = ({
  text,
  color,
  icon,
  iconColor = '#203262',
  to,
  isLargerThan1000 = false,
}: Props) => (
  <Link
    to={to}
    style={{
      flexDirection: 'row',
      display: 'flex',
      alignItems: 'center',
      alignSelf: 'flex-start',
      justifyContent: 'center',
      paddingBottom: isLargerThan1000 ? 64 : 32,
    }}
  >
    <FAIcon icon={icon} color={iconColor} />
    <Text color={color} fontSize="2xl" fontWeight="bold" paddingLeft="5">
      {text}
    </Text>
  </Link>
)

export default NavItem
