import { Box, Flex } from '@chakra-ui/react'
import { t } from 'i18next'
import { Image } from '@chakra-ui/image'
import { Link } from 'react-router-dom'
import NavItem from '../../../NavItem'
import SettingItem from '../../../SettingItem'
import { useAuth } from '../../../../hooks'

type Props = {
  isLargerThan1000: boolean
}
const Menu = ({ isLargerThan1000 }: Props) => {
  const { signOut, profile } = useAuth()

  if (isLargerThan1000) {
    return (
      <Flex
        w="35%"
        h="100vh"
        bg="primary"
        alignItems="center"
        justifyContent="flex-start"
        flexDirection="column"
        flex={1}
        position="sticky"
        top={0}
      >
        <Box padding="10">
          <Link to="/">
            <Image src="/images/logo-white.png" w="8em" />
          </Link>
        </Box>
        <Flex
          flexDirection="column"
          justifyContent="space-between"
          flex={1}
          w="100%"
        >
          <Flex
            flexDirection="column"
            justifyContent="space-evenly"
            padding="10"
          >
            <NavItem
              text={t('dashboard')}
              color={'white'}
              icon={'home'}
              iconColor="white"
              to={'/'}
            />
            <NavItem
              text={t('boosters')}
              color={'white'}
              icon={'user-group'}
              iconColor="white"
              to={'/boosters'}
            />
            <NavItem
              text={t('profile')}
              color={'white'}
              icon={'person'}
              iconColor="white"
              to={`/${profile.uniqueURLid}`}
            />
            <NavItem
              text={'Liidit'}
              color={'white'}
              icon={'briefcase'}
              iconColor="white"
              to={'/leads'}
            />
          </Flex>
          <Flex
            flexDirection="column"
            justifyContent="space-evenly"
            padding="10"
          >
            {/* <SettingItem
              text={t('recoverPasswordPage')}
              color="white"
              iconColor="white"
              to="/recover-password"
            /> */}
            <SettingItem
              text={t('resetPassword')}
              color="white"
              iconColor="white"
              to="/forgot-password"
            />
            {/* <SettingItem
              text="Peru jäsenyys"
              color="white"
              iconColor="white"
              to="/"
            />
            <SettingItem
              text="Tietosuojakäytäntö"
              color="white"
              iconColor="white"
              to="/"
            /> */}
            <SettingItem
              text={t('logout')}
              color="white"
              iconColor="white"
              onClick={signOut}
            />
          </Flex>
        </Flex>
      </Flex>
    )
  }
  return null
}

export default Menu
