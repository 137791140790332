import { Box } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { FormikInput } from '../../Inputs'
import FormikMultiInput from '../../Inputs/FormikMultiInput'

const ProfileLocationArrayForm: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Box>
      <FormikMultiInput
        createObject={{
          address: '',
          city: '',
          zipcode: '',
          primary: '',
        }}
        buttonText={t('addLocation')}
        name="locations"
      >
        {({ index }: any) => (
          <>
            <FormikInput
              name={`locations.${index}.address`}
              placeholder={t('addressPlaceholder')}
            />
            <FormikInput
              name={`locations.${index}.city`}
              placeholder={t('cityPlaceholder')}
            />
            <FormikInput
              name={`locations.${index}.zipcode`}
              placeholder={t('zipcodePlaceholder')}
            />
            <FormikInput
              name={`locations.${index}.primary`}
              label={t('primaryPlaceholder')}
              type="boolean"
            />
          </>
        )}
      </FormikMultiInput>
    </Box>
  )
}

export default ProfileLocationArrayForm
