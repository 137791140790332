import * as yup from 'yup'

export const formValidationSchema = yup.object().shape({
  title: yup.string().required(),
  customer: yup.string().required(),
  customerContact: yup.string().required(),
  customerDescription: yup.string().required(),
  probability: yup.string().required(),
})

export const formReceiverValidationSchema = yup.object().shape({
  closingDate: yup.string().required('Required').typeError('Aseta päivämäärä'),
  // findersFee: yup.string().required(),
  probability: yup.string().required(),
  sellingCoverage: yup.string().required().typeError('Aseta myyntikate'),
  // description: yup.string().min(1),
})
