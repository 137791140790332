import { Flex, Box } from '@chakra-ui/react'
import React from 'react'
import { useParams } from 'react-router'
import Layout from '../../components/Layout'
import { useInternalQuery } from '../../hooks/useQuery'
import { getMeetingQuery } from './query'
import Details from './components/Details'
import MeetingHeading from './components/MeetingHeading'
import { Meeting } from '../../types/meeting'
import { primaryColor, secondaryColor } from '../../theme/styles'

const MeetingView: React.FC = () => {
  const { trainingIdentifier } = useParams()
  const { data, loading, error } = useInternalQuery<Meeting>(
    getMeetingQuery({
      query: {
        uniqueURLid: trainingIdentifier,
      },
    }),
    'meeting',
  )
  const variant = data?.meetingType === 'group' ? secondaryColor : primaryColor

  return (
    <Layout
      loading={loading}
      error={error}
      backButton
      headerBackground={`/images/${data?.meetingType}_placeholder.png`}
      image
    >
      {data && (
        <Flex
          width="100%"
          overflow="hidden"
          flexDir="column"
          position="relative"
        >
          <Box marginTop={10} maxWidth={768}>
            <MeetingHeading data={data} variant={variant || 'primary'} />
            <Details data={data} variant={variant || 'primary'} />
            <Box p={6}>{data.description}</Box>
          </Box>
        </Flex>
      )}
    </Layout>
  )
}

export default MeetingView
