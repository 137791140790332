import { Heading, Box } from '@chakra-ui/react'
import { defaultPadding } from '../../../theme'

interface Props {
  title: String
}

const FormHeader: React.FC<Props> = ({ title, children }) => (
  <Box mt="4" marginY={defaultPadding}>
    <Heading size="md" as="h3" pt="5">
      {title}
    </Heading>
    {children}
  </Box>
)

export default FormHeader
