import React from 'react'
import { Route, BrowserRouter, Routes } from 'react-router-dom'
import Leads from './pages/Leads'
import ProtectedRoute from './components/ProtectedRoute'
import Boosters from './pages/Boosters'
import Dashboard from './pages/Dashboard'

import ForgotPasswordPage from './pages/ForgotPassword'
import LogIn from './pages/LogIn'
import ProfileEdit from './pages/Profile/Edit'
import LeadEdit from './pages/Lead/Edit'
import ProfileView from './pages/Profile/View'
import RecoverPasswordPage from './pages/RecoverPassword'
import Register from './pages/Register'
import MeetingView from './pages/Meetings/View'
import MeetingsListView from './pages/Meetings/listView'
import NewLead from './pages/Lead'

// TODO: protected auth routes
// Tried to do wrapper for the protected route, but new router version is bit weird
// complains if not using the Route component directly
const AppRoutes = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/login" element={<LogIn />} />
      <Route path="/register" element={<Register />} />
      <Route path="/forgot-password" element={<ForgotPasswordPage />} />
      <Route path="/recover-password" element={<RecoverPasswordPage />} />
      <Route
        path="/profile/edit"
        element={
          <ProtectedRoute>
            <ProfileEdit />
          </ProtectedRoute>
        }
      />
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      />

      <Route
        path="/boosters"
        element={
          <ProtectedRoute>
            <Boosters />
          </ProtectedRoute>
        }
      />

      <Route
        path="/leads"
        element={
          <ProtectedRoute>
            <Leads />
          </ProtectedRoute>
        }
      />

      <Route
        path="/newLead"
        element={
          <ProtectedRoute>
            <NewLead />
          </ProtectedRoute>
        }
      />

      <Route
        path="/leads/:identifier"
        element={
          <ProtectedRoute>
            <LeadEdit />
          </ProtectedRoute>
        }
      />

      <Route
        path="/:profileIdentifier"
        element={
          <ProtectedRoute>
            <ProfileView />
          </ProtectedRoute>
        }
      />
      <Route
        path="/meetings"
        element={
          <ProtectedRoute>
            <MeetingsListView />
          </ProtectedRoute>
        }
      />
      <Route
        path="/meetings/:trainingIdentifier"
        element={
          <ProtectedRoute>
            <MeetingView />
          </ProtectedRoute>
        }
      />
    </Routes>
  </BrowserRouter>
)

export default AppRoutes
