import { useQuery } from '@apollo/client'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'
import PageSection from '../../components/Layout/PageSection'
import ProfileCard from '../../components/ProfileCard'
import { Member } from '../../types/member'
import { getProfilesQuery } from '../Profile/query'

const NewMembers: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { data } = useQuery<{ members: Member[] }>(
    getProfilesQuery({
      sortBy: 'CREATEDAT_DESC',
      limit: 10,
    }),
  )

  const members = data?.members || []
  return (
    <PageSection title={t('newMembers')}>
      <Swiper slidesPerView="auto" spaceBetween={8}>
        {members?.map((member) => (
          <SwiperSlide style={{ width: 200, height: 300 }} key={member.userId}>
            <ProfileCard
              data={member}
              key={member.userId}
              onClick={() => navigate(`/${member.uniqueURLid}`)}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </PageSection>
  )
}

export default NewMembers
