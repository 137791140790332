import React from 'react'
import { Flex, Grid, Box, GridItem, Link } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { Member } from '../../types/member'
import { primaryColor } from '../../theme/styles'
import { defaultPadding } from '../../theme'

interface Props {
  profile: Member
}

const ContactDetails: React.FC<Props> = ({ profile }) => {
  const { t } = useTranslation()
  const primaryEmail = profile.emails.find((em) => em.primary)
  return (
    <Box maxW={600}>
      <Grid templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}>
        <ContactListItem href={`tel:${profile.phoneNumber}`} icon="phone">
          {profile.phoneNumber}
        </ContactListItem>
        {profile.twitter && (
          <ContactListItem href={profile.twitter} icon="fa-brands fa-twitter">
            {profile.twitter}
          </ContactListItem>
        )}
        {profile.linkedin && (
          <ContactListItem icon="fa-brands fa-linkedin" href={profile.linkedin}>
            {t('open')}
          </ContactListItem>
        )}
        {primaryEmail && (
          <ContactListItem
            href={`mailto:${primaryEmail.address}`}
            icon="envelope"
          >
            {primaryEmail.address}
          </ContactListItem>
        )}
      </Grid>
    </Box>
  )
}

const ContactListItem: React.FC<{ icon: any; href: string }> = ({
  icon,
  href,
  children,
}) => (
  <GridItem>
    <Link href={href} target="_blank" rel="noreferrer">
      <Flex py={defaultPadding} alignItems="center">
        <FontAwesomeIcon
          style={{ color: primaryColor }}
          icon={icon}
          size="lg"
        />
        <Box ml={5}>{children}</Box>
      </Flex>
    </Link>
  </GridItem>
)

export default ContactDetails
