// @ts-ignore
import { Flex, BoxProps, Box } from '@chakra-ui/layout'
import { Heading, Input, useMediaQuery, Text } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { defaultPadding } from '../../theme'
import BackButton from '../Buttons/BackButton'
import Loader from '../Loader'
import TopBar from './TopBar'
import Menu from './TopBar/Menu'
import BackgroundImage from '../BackgroundImage'

interface Props extends BoxProps {
  loading?: boolean
  error?: Error
  title?: string
  backButton?: boolean
  hideLogo?: boolean
  titleText?: string
  headerBackground?: string
  showImageUpload?: boolean
  handleImageChange?: (event: any) => void
  contentBg?: string | string[]
  showNav?: boolean
  image?: boolean
  showBanner?: boolean
  bannerTitle?: string
  bannerText?: string
  bannerAction?: () => void
}

// TODO: make title work when bg is given
const Layout: React.FC<Props> = ({
  children,
  error,
  title,
  contentBg,
  loading,
  backButton,
  headerBackground,
  handleImageChange,
  showImageUpload,
  showNav = true,
  hideLogo = true,
  titleText = '',
  image = false,
  showBanner = false,
  bannerTitle = '',
  bannerText = '',
  bannerAction,
  ...props
}) => {
  const [isLargerThan1000] = useMediaQuery('(min-width: 1000px)')
  return (
    <Flex w="100%" minH="100vh" direction="row" backgroundColor={contentBg}>
      {showNav && isLargerThan1000 ? <Menu isLargerThan1000 /> : null}
      <Box w={showNav && isLargerThan1000 ? '75%' : '100%'} minH="100vh">
        <Box top={0} pointerEvents="none" left={0} right={0}>
          <TopBar
            LeftComponent={backButton ? BackButton : null}
            bgBehind={!!headerBackground}
            isLargerThan1000={isLargerThan1000}
            hideLogo={hideLogo}
            titleText={titleText}
            showBanner={showBanner}
            bannerTitle={bannerTitle}
            bannerText={bannerText}
            bannerAction={bannerAction}
          />
          {image && <BackgroundImage image={headerBackground} />}
        </Box>
        {showImageUpload && (
          <Flex justify="end" pr={4}>
            <label htmlFor="bg-image-upload">
              <Input
                onChange={handleImageChange}
                type="file"
                display="none"
                accept="image/*"
                id="bg-image-upload"
              />
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <Box cursor="pointer">
                <FontAwesomeIcon
                  style={{ width: '100%' }}
                  size={'2x'}
                  icon="plus"
                />
              </Box>
            </label>
          </Flex>
        )}
        <Flex
          {...props}
          maxW={'100vw'}
          justifyContent="center"
          backgroundColor={'#ffffff'}
        >
          {error && <Text> {error.message || error}</Text>}
          {loading && !error ? <Loader /> : null}

          {!loading && !error ? (
            <Box
              bg="#ffffff"
              paddingBottom={14}
              px={defaultPadding}
              w="100%"
              maxW="1440"
            >
              {title && <Heading mb={defaultPadding}>{title}</Heading>}
              {children}
            </Box>
          ) : null}
        </Flex>
      </Box>
    </Flex>
  )
}

export default Layout
