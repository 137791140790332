import { IconButton, IconButtonProps } from '@chakra-ui/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom'

const BackButton: React.FC<Partial<IconButtonProps>> = (props) => {
  const navigate = useNavigate()
  return (
    <IconButton
      margin={0}
      onClick={() => navigate(-1)}
      {...props}
      aria-label="back"
      variant="none"
      icon={<FontAwesomeIcon icon="chevron-left" />}
    />
  )
}

export default BackButton
