import { Flex, Button } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

export const ScrollTopButton = () => {
  const { t } = useTranslation()

  const handleClick = () => {
    window.scrollTo(0, 0)
  }

  return (
    <Flex marginTop={10} justifyContent="center">
      <Button isFullWidth maxW="768px" variant="outline" onClick={handleClick}>
        {t('backToTop')}
      </Button>
    </Flex>
  )
}

export default ScrollTopButton
