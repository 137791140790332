import { Checkbox, CheckboxProps } from '@chakra-ui/react'
import { useField } from 'formik'
import React, { FC, ReactNode } from 'react'
import { BaseProps, FormControl } from '../form-control'

export type CheckboxSingleProps = BaseProps & {
  checkBoxProps?: CheckboxProps
  children: ReactNode
  otherValues?: any
  index?: number
}

export const CheckboxSingleControl: FC<CheckboxSingleProps> = (
  props: CheckboxSingleProps,
) => {
  const { name, label, children, otherValues, index: i, ...rest } = props
  const [field, meta] = useField(name)
  const isChecked = field.value
  const otherFields = (): boolean | undefined =>
    otherValues?.find(
      (item: any, index: number) => item.primary === true && index !== i,
    )

  return (
    <FormControl name={name} {...rest} alignItems="center" display="flex">
      <Checkbox
        {...field}
        borderColor="secondary"
        id={name}
        colorScheme="secondaryScheme"
        color="primary"
        isInvalid={!!meta.error && meta.touched}
        isDisabled={otherFields()}
        isChecked={isChecked}
      >
        {label}
        {children}
      </Checkbox>
    </FormControl>
  )
}
