import React from 'react'
import { Modal } from '@chakra-ui/react'

type Props = {
  children: JSX.Element
  isOpen: boolean
  onClose: () => void
}

const CustomModal: React.FC<Props> = ({ children, isOpen, onClose }) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    {children}
  </Modal>
)

export default CustomModal
