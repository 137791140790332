import { Box, Flex, Spinner, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { gql, useQuery } from '@apollo/client'
import { Link } from 'react-router-dom'
import { useDebounce } from 'use-debounce'
import Input from '../../components/basic/Input'
import BasicButton from '../../components/Buttons/BasicButton'
import FAIcon from '../../components/FAIcon'
import Layout from '../../components/Layout'
import LeadCard from '../../components/LeadCard'
import NewLead from '../../components/NewLead'
import { green, primaryColor } from '../../theme/styles'
import { Lead, LeadStatus } from '../../types/lead'
import { defaultPadding } from '../../theme'
import { leadQueryProperties } from './query'
import useLeads from '../../hooks/useLeads'

type Props = {}

const Leads: React.FC<Props> = () => {
  const [newLeads, setNewLeads] = useState<Lead[]>([])
  const [openLeads, setOpenLeads] = useState<Lead[]>([])
  const [filteredLeads, setFilteredLeads] = useState<Lead[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const { leads } = useLeads()
  const [value, setValue] = useState<string>('')
  const [searchWord] = useDebounce(value, 1500)

  const query = gql`
    query leadSearch($input: String!) {
      leadSearch(input: $input) {
        ${leadQueryProperties}
      }
    }
  `
  const { data, refetch } = useQuery(query, {
    variables: {
      input: '',
    },
  })

  const results = data?.leadSearch || []

  useEffect(() => {
    getResults()
    filterNewLeads(results)
  }, [searchWord, results])

  useEffect(() => {
    refetch()
    if (leads) {
      sortLeads(leads)
    }
  }, [leads])

  const getResults = async () => {
    if (refetch && value) {
      await refetch({
        input: searchWord,
      })
      setLoading(false)
    }
    setLoading(false)
  }

  const filterNewLeads = (leadsResults: any) => {
    const filteredResults = leadsResults.filter(
      (r: any) => r.status !== LeadStatus.New && r.status !== LeadStatus.Open,
    )
    const leadsOpen = leadsResults.filter(
      (lead: Lead) => lead.status === LeadStatus.Open,
    )
    setFilteredLeads(filteredResults)
    setOpenLeads(leadsOpen)
  }

  const onChangeSearch = (text: string) => {
    setLoading(true)
    setValue(text)
  }

  const sortLeads = (allLeads: Lead[]) => {
    const onlyStatusNew = allLeads?.filter(
      (lead) => lead.status === LeadStatus.New,
    )
    setNewLeads(onlyStatusNew)
  }

  return (
    <Layout>
      <Link to="/newLead">
        <BasicButton
          text="Välitä uusi liidi"
          color={primaryColor}
          textColor={'#ffffff'}
          onClick={() => {}}
        />
      </Link>
      <Flex alignItems="center" pl="5" pt="10" pb="10">
        <FAIcon size="2x" icon="circle-exclamation" color={green} />
        <Text pl="3" fontSize="larger" color={green} fontWeight="bold">
          Uudet liidit ({newLeads.length})
        </Text>
      </Flex>
      {newLeads.length > 0 &&
        newLeads.map((lead: Lead) => (
          <NewLead lead={lead} refetch={refetch} /> //eslint-disable-line
        ))}
      <hr color={primaryColor} />
      <Box padding={defaultPadding} pt="10">
        <Text fontSize={'2xl'} color={primaryColor} fontWeight={'bold'} mb="5">
          Liidit
        </Text>
        <Input
          value={value}
          onChange={(event) => onChangeSearch(event.target.value)}
          Left={<FAIcon icon={'search'} />}
          placeholder={'Etsi liidejä hakusanalla tai yrityksen nimellä.'}
          marginBottom={defaultPadding}
        />
        {loading && (
          <Flex alignItems="center" justifyContent="center" direction="row">
            <Spinner />
          </Flex>
        )}
      </Box>
      {openLeads &&
        openLeads.length > 0 &&
        openLeads.map((lead: Lead) => (
          <LeadCard status={lead.status} lead={lead} /> //eslint-disable-line
        ))}
      <br />
      {filteredLeads &&
        filteredLeads.length > 0 &&
        filteredLeads.map((lead: Lead) => (
          <LeadCard status={lead.status} lead={lead} /> //eslint-disable-line
        ))}
    </Layout>
  )
}

export default Leads
